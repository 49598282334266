import Vue from 'vue';
import './plugins/axios';
import './plugins/vuetify';
import vuelidate from 'vuelidate';
import App from './App.vue';
import moment from 'moment';
import VueGtag from "vue-gtag";
// import './assets/css/font-awesome-css.min.css'
import './assets/css/style.css';
import './assets/css/bc.css';
import i18n from './i18n';
import 'url-search-params-polyfill';

// Partial Polyfill for IE 11 (for Axios Post)
/*(function (w) {

  w.URLSearchParams = w.URLSearchParams || function (searchString) {
    var self = this;
    self.searchString = searchString;
    self.get = function (name) {
      var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
      if (results == null) {
        return null;
      }
      else {
        return decodeURI(results[1]) || 0;
      }
    };
  }

})(window)*/
// /Polyfill

Vue.config.productionTip = false;
// Vue.config.devtools = true;

Vue.use(vuelidate);

Vue.use(VueGtag, {
  config: { id: "AW-1054537232" }
});

Vue.filter('formatDate', function (value) {
  if (value) {
    /* if (lang === 'de') {
      return moment(String(value)).format('DD.MM.YYYY')
    } else if (lang === 'en') {
      return moment(String(value)).format('YYYY-MM-DD')
    } */
    return new moment(String(value)).toDate().toLocaleString().replace(/[^ -~]/g, '')
  }
});


new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app');
