import webtrekkSmartPixelVueJS from 'webtrekk-smart-pixel-vuejs';

const tra_page_defaults = {
    category: {
        1: 'de|Germany',  // Country | Language (CG 1): language|country - Bsp. "en|india"
        7: '[germany|de].[Checkout Setp 1]',  // English page name (CG7): [country|language].[page name] - Bsp. "[spain|es].[Checkout Step 1]"
        14: 'docebo checkout',  // Page Type (CG 14)
    },
    parameter: {
        1: 'A',  // TUV Business Stream (PP 1)
        2: 'A.02 Training and HR Development',  // TUV Business Field (PP 2)
        3: 'no Service ID',  // Service ID (PP 3)
        4: '',  // TUV Country Website (PP 4)
        5: '',  // TUV Region (PP 5)
        7: 'docebo',  // Platform (PP 7)
        13: '',  // B2B/ B2C (PP 13)
        14: 'no login status',  // Login Status (PP 14)
    },
};

var ApplicationTracking = function () {
    this.init = function () {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            wtSmart.init.set({
                trackId: '350258498646185',  // Test: 930969066383066 | Prod: 350258498646185
                trackDomain: 'q3.webtrekk.net',
            });
        });
    };

    this.page = function (name, data) {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            let wtData = {...tra_page_defaults, ...data};
            wtSmart.page.data.set(name, wtData);
        });
    };

    this.pageAdd = function (name, data) {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            wtSmart.page.data.add(name, data);
        });
    };

    this.action = function (data) {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            wtSmart.action.data.set(data);
        });
    };

    this.track = function () {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            wtSmart.track();
        });
    };

    this.trackPage = function () {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            wtSmart.trackPage();
        });
    };

    this.trackAction = function () {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            wtSmart.trackAction();
        });
    };

    this.setBasket = function (productList) {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            /* var product = {
              id: 'ABC-123',
              cost: 99.90,
              quantity: 2,
              soldOut: false,
              variant: 'green',
              parameter: {
                1: 'L'
              },
              category: {
                1: 'tops',
                2: 'noname'
              }
            };

            wtSmart.product.basket.data.set([product, product2]);
            */
            wtSmart.product.basket.data.set(productList);
        });
    };

    this.setOrderConfirmed = function (productList, orderData) {
        webtrekkSmartPixelVueJS.call(function (wtSmart) {
            /* var product = {
              id: 'ABC-123',
              cost: 99.90,
              quantity: 2,
              soldOut: false,
              variant: 'green',
              parameter: {
                1: 'L'
              },
              category: {
                1: 'tops',
                2: 'noname'
              }
            };

            wtSmart.product.basket.data.set([product, product2]);
            */
            wtSmart.product.confirmation.data.set(productList);

            /*
            wtSmart.order.data.set({
                value: 120.99,
                id: 'M-12345',
                couponValue: 10.00,
                paymentMethod: 'paypal',
                shippingService: 'dhl',
                shippingSpeed: 'express',
                shippingCosts: 4.95,
                grossMargin: 12.95,
                parameter: {
                    5: 'bill'
                }
            });
            */
            wtSmart.order.data.set(orderData);
        });
    };

};

export default new ApplicationTracking();
