<template>
    <div>
        <h1 class="title">{{ $t("billingHeader") }}</h1>

        <!-- <h2 class="subtitle">
            Where should we send your freshly roasted coffee beans?
        </h2> -->

        <form class="form">

            <!-- <div class="form-group">
                <label class="form-label" for="anrede">Anrede</label>
                <input v-model="$v.form.anrede.$model" type="text" placeholder="Anrede" class="form-control"
                       id="anrede">
                <div v-if="$v.form.anrede.$error" class="alert alert-danger">Dies ist ein Pflichtfeld.</div>
            </div> -->

            <div>
                <div class="col-1-3">
                    <div>{{ $t("pleaseSpecifyCustomerType") }}:</div>
                    <ul class="tuv-radiobox-list tuv-radiobox-list--transparent">
                        <li>
                            <label class="tuv-radiobox">
                                <input type="radio" class="tuv-radiobox__control" name="customerType" v-model="form.customerType" value="private">
                                <span class="tuv-radiobox__icon fa fa-check"></span>
                                <span class="tuv-radiobox__label">{{ $t("iamPrivateClient") }}</span>
                                <span class="tuv-form__error-message">Text field error message</span>
                            </label>
                        </li>
                        <li>
                            <label class="tuv-radiobox" tuv-radiobox--error="">
                                <input type="radio" class="tuv-radiobox__control" name="customerType" v-model="form.customerType" value="business">
                                <span class="tuv-radiobox__icon fa fa-check"></span>
                                <span class="tuv-radiobox__label">{{ $t("iamBusinessClient") }}</span>
                                <span class="tuv-form__error-message">Text field error message</span>
                            </label>
                        </li>
                    </ul>
                    <br><br>
                </div>
            </div>

            <div class="tuv-contactform-wrapper tuv-container">
                <div class="component tuv-jc-registration">
                    <h2>{{ $t("yourPersonalContact") }}</h2>
                    <p>{{ $t("pleaseProvideInfoAboutYou") }}</p>
                    <form action="">
                        <div class="clearfix">
                            <label for="firstName" class="tuv-jc-registration__label tuv-jc-registration__label--left">
                                {{ $t("firstName") }} *
                                <input  class="tuv-input__control tuv-input__control--left"
                                        id="firstName"
                                        name="firstName"
                                        v-model="form.firstName"
                                        type="text"
                                        required>
                                <span v-if="$v.form.firstName.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="name" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                                {{ $t("lastName") }} *
                                <input  class="tuv-input__control"
                                        id="name"
                                        name="name"
                                        v-model="form.lastName"
                                        type="text"
                                        required>
                                <span v-if="$v.form.lastName.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="email" class="tuv-jc-registration__label">
                                {{ $t("email") }} *
                                <input  class="tuv-input__control"
                                        id="email"
                                        name="email"
                                        v-model="form.email"
                                        type="email"
                                        required>
                                <span v-if="$v.form.email.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="street" class="tuv-jc-registration__label">
                                {{ $t("address") }} *
                                <input  class="tuv-input__control tuv-input__control"
                                        id="street"
                                        name="street"
                                        v-model="form.street"
                                        type="text"
                                        required>
                                <span v-if="$v.form.street.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <!-- <label for="housenumber" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                                Housenumber
                                <input  class="tuv-input__control"
                                        id="housenumber"
                                        name="housenumber"
                                        v-model="form.housenumber"
                                        type="text"
                                        required>
                                <span v-if="$v.form.housenumber.$error" class="bc-tuv-form__error-message">This information is required</span>
                            </label> -->
                            <label for="zip" class="tuv-jc-registration__label tuv-jc-registration__label--left">
                                {{ $t("zip") }} *
                                <input class="tuv-input__control tuv-input__control--left"
                                       id="zip"
                                       name="zip"
                                       v-model="form.zip"
                                       type="text"
                                       maxlength="10"
                                       required>
                                <span v-if="$v.form.zip.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="city" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                                {{ $t("city") }} *
                                <input  class="tuv-input__control"
                                        id="city"
                                        name="city"
                                        v-model="form.city"
                                        type="text"
                                        required>
                                <span v-if="$v.form.city.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="country" class="tuv-jc-registration__label">
                                {{ $t("country") }} *
                                <input  class="tuv-input__control"
                                        id="country"
                                        name="country"
                                        v-model="form.country"
                                        type="text"
                                        required>
                                <span v-if="$v.form.country.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>

                        </div>
                        <!-- <div>
                            <br>
                            <small>{{{data.pre-privacy}}}</small>
                            <label class="tuv-checkbox">
                                <input type="checkbox" class="tuv-checkbox__control" name="checkbox" required>
                                <span class="tuv-checkbox__icon fa fa-check"></span>
                                <span class="tuv-checkbox__label">{{{data.privacy}}}</span>
                            </label>
                        </div> -->
                        <!-- <div class="button-wrapper">
                            <button class="tuv-btn">{{data.submit}}</button>
                        </div> -->
                    </form>
                </div>
            </div>

            <div v-if="form.customerType === 'business'" class="tuv-contactform-wrapper tuv-container">
                <div class="component tuv-jc-registration">
                    <h2>{{ $t("yourBusinessContact") }}</h2>
                    <p>{{ $t("pleaseProvideInfoAboutBusiness") }}</p>
                    <form action="">
                        <div class="clearfix">
                            <label for="companyName" class="tuv-jc-registration__label tuv-jc-registration__label--left">
                                {{ $t("companyName") }} *
                                <input  class="tuv-input__control tuv-input__control--left"
                                        id="companyName"
                                        name="companyName"
                                        v-model="form.companyName"
                                        type="text"
                                        required>
                                <span v-if="$v.form.companyName.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="companyVat" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                                {{ $t("vatId") }} *
                                <input  class="tuv-input__control"
                                        id="companyVat"
                                        name="companyVat"
                                        v-model="form.companyVat"
                                        type="text"
                                        required>
                                <span v-if="$v.form.companyVat.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="companyIndustry" class="tuv-jc-registration__label tuv-jc-registration__label--left">
                                {{ $t("industry") }}
                                <input  class="tuv-input__control tuv-input__control--left"
                                        id="companyIndustry"
                                        name="companyIndustry"
                                        v-model="form.companyIndustry"
                                        type="text"
                                        required>
                                <span v-if="$v.form.companyIndustry.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="companyStreet" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                                {{ $t("address") }} *
                                <input  class="tuv-input__control"
                                        id="companyStreet"
                                        name="companyStreet"
                                        v-model="form.companyStreet"
                                        type="text"
                                        required>
                                <span v-if="$v.form.companyStreet.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="companyZip" class="tuv-jc-registration__label tuv-jc-registration__label--left">
                                {{ $t("zip") }} *
                                <input class="tuv-input__control tuv-input__control--left"
                                       id="companyZip"
                                       name="companyZip"
                                       v-model="form.companyZip"
                                       type="text"
                                       maxlength="10"
                                       required>
                                <span v-if="$v.form.companyZip.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="companyCity" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                                {{ $t("city") }} *
                                <input  class="tuv-input__control"
                                        id="companyCity"
                                        name="companyCity"
                                        v-model="form.companyCity"
                                        type="text"
                                        required>
                                <span v-if="$v.form.companyCity.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <label for="companyCountry" class="tuv-jc-registration__label">
                                {{ $t("country") }} *
                                <input  class="tuv-input__control"
                                        id="companyCountry"
                                        name="companyCountry"
                                        v-model="form.companyCountry"
                                        type="text"
                                        required>
                                <span v-if="$v.form.companyCountry.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                            </label>
                            <!-- <label for="department" class="tuv-jc-registration__label">
                                Department
                                <input  class="tuv-input__control"
                                        id="department"
                                        name="department"
                                        v-model="form.department"
                                        type="text"
                                        required>
                                <span v-if="$v.form.department.$error" class="bc-tuv-form__error-message">This information is required</span>
                            </label> -->
                        </div>
                    </form>
                </div>
            </div>

            <div class="component tuv-jc-registration">
                <h2>{{ $t("billing") }}</h2>
                <p>{{ $t("whereToSendInvoice") }}</p>
                <div class="component tuv-container">
                    <ul class="tuv-check-list">
                        <li>
                            <label class="tuv-checkbox ">
                                <input v-model="form.billingIsIdentical" type="checkbox" class="tuv-checkbox__control" name="checkbox">
                                <span class="tuv-checkbox__icon fa fa-check"></span>
                                <span class="tuv-checkbox__label">{{ $t("sameBillingAddress") }}</span>
                                <!-- <span class="tuv-form__error-message">Text field error message</span> -->
                            </label>
                        </li>
                    </ul>
                </div>
                <form v-if="!form.billingIsIdentical" action="">
                    <div class="clearfix">
                        <label for="billingName" class="tuv-jc-registration__label tuv-jc-registration__label--left">
                            {{ $t("nameOrCompany") }} *
                            <input  class="tuv-input__control tuv-input__control--left"
                                    id="billingName"
                                    name="billingName"
                                    v-model="form.billingName"
                                    type="text"
                                    required>
                            <span v-if="$v.form.billingName.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                        </label>
                        <label for="billingVat" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                            {{ $t("vatId") }} *
                            <input  class="tuv-input__control tuv-input__control--right"
                                    id="billingVat"
                                    name="billingVat"
                                    v-model="form.billingVat"
                                    type="text"
                                    required>
                            <span v-if="$v.form.billingVat.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                        </label>
                        <label for="billingStreet" class="tuv-jc-registration__label">
                            {{ $t("address") }} *
                            <input  class="tuv-input__control tuv-input__control"
                                    id="billingStreet"
                                    name="billingStreet"
                                    v-model="form.billingStreet"
                                    type="text"
                                    required>
                            <span v-if="$v.form.billingStreet.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                        </label>
                        <!-- <label for="billingHousenumber" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                            Housenumber
                            <input  class="tuv-input__control tuv-input__control--right"
                                    id="billingHousenumber"
                                    name="billingHousenumber"
                                    v-model="form.billingHousenumber"
                                    type="text"
                                    required>
                            <span v-if="$v.form.billingHousenumber.$error" class="bc-tuv-form__error-message">This information is required</span>
                        </label> -->
                        <label for="billingZip" class="tuv-jc-registration__label tuv-jc-registration__label--left">
                            {{ $t("zip") }} *
                            <input class="tuv-input__control tuv-input__control--left"
                                   id="billingZip"
                                   name="billingZip"
                                   v-model="form.billingZip"
                                   type="text"
                                   maxlength="10"
                                   required>
                            <span v-if="$v.form.billingZip.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                        </label>
                        <label for="billingCity" class="tuv-jc-registration__label tuv-jc-registration__label--right">
                            {{ $t("city") }} *
                            <input  class="tuv-input__control tuv-input__control--right"
                                    id="billingCity"
                                    name="billingCity"
                                    v-model="form.billingCity"
                                    type="text"
                                    required>
                            <span v-if="$v.form.billingCity.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                        </label>
                        <label for="billingCountry" class="tuv-jc-registration__label">
                            {{ $t("country") }} *
                            <input  class="tuv-input__control"
                                    id="billingCountry"
                                    name="billingCountry"
                                    v-model="form.billingCountry"
                                    type="text"
                                    required>
                            <span v-if="$v.form.billingCountry.$error" class="bc-tuv-form__error-message">{{ $t("pleaseProvideInfo") }}</span>
                        </label>
                    </div>
                </form>
            </div>
            <div style="height: 50px;"></div>
        </form>
    </div>
</template>

<script>
import {email, maxLength, required, requiredIf} from 'vuelidate/lib/validators';
import {countryRegion} from '@/helper/wtMappings';
import applicationTracking from '@/plugins/webtrekk';

export default {
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        firstName: null,
                    lastName: null,
                    email: null,
                    street: null,
                    // housenumber: null,
                    zip: null,
                    city: null,
                    country: null,
                    doceboCountry: this.wizardData.doceboCountry,

                    companyName: null,
                    companyIndustry: null,
                    companyStreet: null,
                    companyZip: null,
                    companyCity: null,
                    companyCountry: null,
                    companyVat: null,
                    department: null,

          billingIsIdentical: true,
        billingName: null,
        billingStreet: null,
        // billingHousenumber: null,
        billingZip: null,
        billingCity: null,
        billingCountry: null,
        billingVat: null,

        customerType: "private",

        /*
                  anrede: 'Herr',
                  vorname: null,
                  nachname: null,
                  geburtsdatum: null,
                  plz: null,
                  ort: null,
                  strasse: null,
                  hausnummer: null,
                  telefon_vorwahl: null,
                  telefon: null,
                  email: null,
                  email2: null,
        */
      },
      address: null,
    };
  },
  mounted: function () {
    let wtContentId = `[${this.$i18n.locale}|${this.wizardData.micrositeLanguage}].[Checkout Step 2]`;
    let wtData = {
      category: {
        1: `${this.wizardData.micrositeLanguage}|${this.$i18n.locale}`,  // Country | Language (CG 1): language|country - Bsp. "en|india"
        7: `[${this.$i18n.locale}|${this.wizardData.micrositeLanguage}].[Checkout Step 2]`,  // English page name (CG7): [country|language].[page name] - Bsp. "[spain|es].[Checkout Step 1]"
        14: 'docebo checkout',  // Page Type (CG 14)
      },
      parameter: {
        1: 'A',  // TUV Business Stream (PP 1)
        2: 'A.02 Training and HR Development',  // TUV Business Field (PP 2)
        3: 'no Service ID',  // Service ID (PP 3)
        4: `${this.$i18n.locale}`,  // TUV Country Website (PP 4)
        5: countryRegion[this.$i18n.locale] || '',  // TUV Region (PP 5)
        7: 'docebo',  // Platform (PP 7)
        13: '',  // B2B/ B2C (PP 13)
        14: 'no login status',  // Login Status (PP 14)
      },
    };
    applicationTracking.page(wtContentId, wtData);
    applicationTracking.trackPage();
  },
  activated() {
    /* this.form.recipient = this.wizardData.name;
    this.form.plz = this.wizardData.plz;
    this.form.ort = this.wizardData.ort;
    this.form.strasse = this.wizardData.strasse;
    this.form.hausnummer = this.wizardData.hausnummer; */

    const cart = this.wizardData.cart;
    const firstProduct = cart[Object.keys(cart)[0]];
    const firstParticipant = firstProduct.participants[0];
    const loaded = this.load();
    if (!loaded && firstParticipant !== 'undefined') {
      this.form.firstName = firstParticipant.firstName;
      this.form.lastName = firstParticipant.lastName;
      this.form.email = firstParticipant.email;
      this.form.country = this.wizardData.land;
        this.$forceUpdate()
      }
    },
    validations: {
      // address: {required, AddressValidator},
      form: {
/*
        anrede: {required, AnredeValidator},
        vorname: {required, alpha},
        nachname: {required, alpha},
        geburtsdatum: {required},
        plz: {required, integer},
        ort: {required},
        strasse: {required},
        hausnummer: {required, integer},
        telefon_vorwahl: {required},
        telefon: {required},
        email: {required, email},
        email2: {required, email, sameAsEmail: sameAs('email')},
*/
        // PRIVATE
        customerType: {required},
        firstName: {required},
        lastName: {required},
        email: {required, email},
        zip: {required, maxLength: maxLength(10)},
        city: {required},
        country: {required},
        street: {required},
        // housenumber: {required},

        // COMPANY
        companyName: {
          required: requiredIf(function () {
            return this.form.customerType === 'business'
          })
        },
        companyVat: {
          required: requiredIf(function () {
            return this.form.customerType === 'business'
          })
        },
        companyIndustry: {},
        companyStreet: {
          required: requiredIf(function () {
            return this.form.customerType === 'business'
          })
        },
        companyZip: {
          maxLength: maxLength(10),
          required: requiredIf(function () {
            return this.form.customerType === 'business'
          })
        },
        companyCity: {
          required: requiredIf(function () {
            return this.form.customerType === 'business'
          })
        },
        companyCountry: {
          required: requiredIf(function () {
            return this.form.customerType === 'business'
          })
        },
        /* department: {
          required: requiredIf(function () {
            return this.form.customerType === 'business'
          })
        }, */

        // BILLING
        billingName: {
          required: requiredIf(function () {
            return !this.form.billingIsIdentical
          })
        },
        billingZip: {
          maxLength: maxLength(10),
          required: requiredIf(function () {
            return !this.form.billingIsIdentical
          })
        },
        billingCity: {
          required: requiredIf(function () {
            return !this.form.billingIsIdentical
          })
        },
        billingStreet: {
          required: requiredIf(function () {
            return !this.form.billingIsIdentical
          })
        },
        billingCountry: {
          required: requiredIf(function () {
            return !this.form.billingIsIdentical
          })
        },
        billingVat: {
          required: requiredIf(function () {
            return !this.form.billingIsIdentical
          })
        },
        /* billingHousenumber: {
          required: requiredIf(function () {
            return !this.form.billingIsIdentical
          })
        }, */

      },
    },
    methods: {
      submit() {
        this.$v.$touch();
        this.save();
        return new Promise((resolve, reject) => {
          if (!this.$v.$invalid) {
            resolve({
              // anrede: this.form.anrede,
              vorname: this.form.firstName,
              nachname: this.form.lastName,
              // geburtsdatum: this.form.geburtsdatum,
              plz: this.form.zip,
              ort: this.form.city,
              land: this.form.country,
              strasse: this.form.street,
              // hausnummer: this.form.housenumber,
              // telefon_vorwahl: this.form.telefon_vorwahl,
              // telefon: this.form.telefon,
              email: this.form.email,

              abweichendeRng: !this.form.billingIsIdentical,
              /* rngName: this.form.billingName,
              rngStrasse: this.form.billingStreet,
              rngPlz: this.form.billingZip,
              rngOrt: this.form.billingCity,
              rngLand: this.form.billingCountry,
              rngVatId: this.form.billingVat, */
              rngName: !this.form.billingIsIdentical ? this.form.billingName : (this.form.customerType === 'business' ? this.form.companyName : this.form.firstName + ' ' + this.form.lastName),
              rngStrasse: !this.form.billingIsIdentical ? this.form.billingStreet : (this.form.customerType === 'business' ? this.form.companyStreet : this.form.street),
              rngPlz: !this.form.billingIsIdentical ? this.form.billingZip : (this.form.customerType === 'business' ? this.form.companyZip : this.form.zip),
              rngOrt: !this.form.billingIsIdentical ? this.form.billingCity : (this.form.customerType === 'business' ? this.form.companyCity : this.form.city),
              rngLand: !this.form.billingIsIdentical ? this.form.billingCountry : (this.form.customerType === 'business' ? this.form.companyCountry : this.form.country),
              rngVatId: !this.form.billingIsIdentical ? this.form.billingVat : this.form.companyVat,

              isCompany: this.form.customerType === 'business',
              companyName: this.form.companyName,
              companyIndustry: this.form.companyIndustry,
              companyStreet: this.form.companyStreet,
              companyZip: this.form.companyZip,
              companyCity: this.form.companyCity,
              companyCountry: this.form.companyCountry,
              companyDepartment: this.form.department,
              companyVatId: this.form.companyVat,
            });
            let wtCustomerType = this.form.customerType === 'business' ? 'B2B' : 'B2C';
            applicationTracking.pageAdd({parameter: {13: wtCustomerType}});
          } else {
            reject('invalid address');
          }
        });
      },
      save() {
        const parsed = JSON.stringify(this.form);
        localStorage.setItem('tra_checkout_billing', parsed);
      },
      load() {
        if (localStorage.getItem('tra_checkout_billing')) {
          try {
            this.form = JSON.parse(localStorage.getItem('tra_checkout_billing'));
            return true;
          } catch (e) {
            localStorage.removeItem('tra_checkout_billing');
            return false;
          }
        }
        return false;
      }
    },
  components: {
    // VueGoogleAutocomplete,

  },
};
</script>

<style scoped>
    div.form-inline > div.form-group > input {
        margin-left: 0.5em;
        margin-right: 1em;
    }
</style>