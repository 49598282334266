import axios from 'axios';

const API_DOMAIN = 'https://checkout.academy.tuv.com';
// const API_DOMAIN = 'https://checkout.tra.bergmann.consulting';
// const API_DOMAIN = 'http://127.0.0.1:5000';

/* export const getCourse = course_id => {
  return new Promise((resolve) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.get(API_DOMAIN + '/api/course/' + course_id, config).then(function (response) {
      resolve(response.data);
    });
  });
}; */

export async function getCourse(course_id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios.get(API_DOMAIN + '/api/course/' + course_id, config)
}

// export const getUserCanBook = (email, cartpos, partpos, country) => {
export const getUserCanBook = (email, country) => {
  return new Promise((resolve) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.get(`${API_DOMAIN}/api/user/${email}/canbookin/${country}`, config).then(
        function (response) {
          resolve(response.data);
        });
    /* .catch(function (error) {
      // pass
    }); */
  });
};

export const postOrder = (form) => {
  return new Promise((resolve,) => {
    const config = {
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json'
      }
    };
    const contactData = {
      'name': form.vorname + ' ' + form.nachname,
      'firstName': form.vorname,
      'lastName': form.nachname,
      'street': form.strasse,
      'zip': form.plz,
      'city': form.ort,
      'country': form.land,
      'email': form.email,
    };
    const billingData = {
      'name': form.rngName !== null? form.rngName : (form.companyName !== null? form.companyName : form.firstName + ' ' + form.lastName),
      'street': form.rngStrasse !== null? form.rngStrasse : (form.companyStreet !== null? form.companyStreet : form.strasse),
      'zip': form.rngPlz !== null? form.rngPlz : (form.companyZip !== null? form.companyZip : form.plz),
      'city': form.rngOrt !== null? form.rngOrt : (form.companyCity !== null? form.companyCity : form.ort),
      'country': form.rngLand !== null? form.rngLand : (form.companyCountry !== null? form.companyCountry : form.land),
      'vatId': form.rngVatId !== null? form.rngVatId : form.companyVatId,
    };
    const data = {
      'isCompany': form.isCompany,
      'companyName': form.companyName,
      'companyDepartment': form.companyDepartment,
      'companyIndustry': form.companyIndustry,
      'companyVatId': form.companyVatId,
      'companyAddress': form.companyStreet + ', ' + form.companyZip + ' ' + form.companyCity + ', ' + form.companyCountry,
      'contactData': contactData,
      'billingData': billingData,
      'items': form.cart,
      'doceboCountry': form.doceboCountry,

    };

    //axios.post(API_DOMAIN + '/api/order', qs.stringify(data),
    axios.post(API_DOMAIN + '/api/order', data,
        config).then(function(response) {
      resolve(response.data);
    }); /* .catch(function(error) {

    }); */
  })
};

/* export const postCustomRequest = (form) => {
  return new Promise((resolve,) => {
    const config = {
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json'
      }
    };
    const data = {
      'country': form.doceboCountry,
      'course': form.course,
      'customDate': form.customDate,
      'customVenue': form.customVenue,
      'customNumberOfParticipants': form.customNumberOfParticipants,
      'customFirstname': form.customFirstname,
      'customLastname': form.customLastname,
      'customCompanyName': form.customCompanyName,
      'customStreet': form.customStreet,
      'customZip': form.customZip,
      'customCity': form.customCity,
      'customCountry': form.customCountry,
      'customEmail': form.customEmail,
      'customPhone': form.customPhone,
      'customComment': form.customComment,
    };

    //axios.post(API_DOMAIN + '/api/order', qs.stringify(data),
    axios.post(API_DOMAIN + '/api/custom_request', data,
        config).then(function(response) {
      resolve(form);
    });
  })
}; */