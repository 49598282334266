<template>
  <div>
    <div v-if="debug" id="debug"
         style="background-color: darkslategray; color: lightgreen; border: 1px solid lightgreen;">
      <ul>
        <li v-for="(msg, mkey) in debugmsg" :key="mkey">{{ msg }}</li>
      </ul>

      <!-- DEV -->
      <!-- <div>
          <button @click="save">Save</button>&nbsp;<button @click="load">Load</button>
      </div> -->
    </div>

    <div v-if="wizardInProgress" v-show="asyncState !== 'pending'">

      <div v-if="abortProcess">
        <div class="tuv-container__title-box">
          <h2>Something went wrong</h2>
          <p><a :href="this.$t('backToOriginUrl')"><i class="fa fa-chevron-right"></i>
            {{ $t('backToOrigin') }}
          </a></p>
        </div>
      </div>
      <div v-else>
        <v-stepper v-model="e1" alt-labels>
          <v-stepper-header>
            <!-- <v-stepper-step :complete="e1 > 1" step="1">{{ $t("session") }}</v-stepper-step>
            <v-divider></v-divider> -->
            <v-stepper-step :complete="e1 > 1" step="1">{{ $t('cart') }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">{{ $t('billing') }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3">{{ $t('summary') }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 4" step="4">{{ $t('confirmation') }}</v-stepper-step>
            <!-- <v-divider></v-divider> -->
            <!-- <v-stepper-step :complete="e1 > 4" step="4">Auftragsdaten</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 5" step="5">Rechnungsadresse</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 6" step="6">Zahlungsweise</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="7">Zusammenfassung</v-stepper-step> -->
          </v-stepper-header>
        </v-stepper>

        <keep-alive>
          <!-- <component
                  v-if="!init"
                  ref="currentStep"
                  :is="currentStep"
                  @updateAsyncState="updateAsyncState"
                  @productActivated="productActivated"
                  @CustomRequestSent="quit"
                  @setShowNavButtons="setShowNavButtons"
                  :wizard-data="form"
          ></component> -->
          <component
              :is="currentStep"
              v-if="!init"
              ref="currentStep"
              :wizard-data="form"
              @CustomRequestSent="quit"
              @setShowNavButtons="setShowNavButtons"
              @updateAsyncState="updateAsyncState"
          ></component>
        </keep-alive>

        <!-- <div class="progress">
            <div :style="`width: ${progress}%;`"></div>
        </div> -->

        <!-- Actions -->
        <div v-if="showNavButtons" class="tuv-btn--right buttons">
          <button
              class="btn tuv-btn--secondary"
              @click="goToOrigin"
          ><i class="fa fa-chevron-left"></i> {{ $t('backToOrigin') }}
          </button>&nbsp;

          <button
              v-if="currentStepNumber > 1"
              class="btn btn-default"
              @click="goBack"
          ><i class="fa fa-chevron-left"></i> {{ $t('back') }}
          </button>&nbsp;

          <button
              class="btn btn-primary"
              @click="nextButtonAction"
          >{{ isLastStep ? $t('orderNow') : $t('continue') }}
            <i class="fa fa-chevron-right"></i></button>
        </div>
        <div class="floatClear"/>
      </div>
    </div>

    <div v-else>
      <div v-if="paymentState === 'paid'">
        <h1 class="title">{{ $t('confirmed') }}</h1>
        <h2 class="subtitle">
          {{ $t('confirmedMessage') }}
        </h2>
      </div>
      <div v-if="paymentState === 'failed'">
        <h1 class="title">Something went wrong</h1>
        <h2 class="subtitle">
          Your payment has failed. Thus, we were not able to enroll attendees. Please contact support.
        </h2>
        <img alt="scared cat" src="../assets/img/bc/cat.png"/>

        <!-- FAILED PAYMENT Actions -->
        <div class="tuv-btn--right buttons">
          <button
              class="btn btn-primary"
              @click="goToStepNo(2)"
          >Try again?
            <i class="fa fa-chevron-right"></i></button>
        </div>
        <div class="floatClear"/>
      </div>
      <div v-if="['paid', 'failed'].indexOf(paymentState) < 0" class="loading-wrapper">
        <!-- Payment state not yet available -->
        <div class="lds-container">
          <div class="lds-dual-ring"></div>
          <h2>{{ $t('loading') }}</h2>
        </div>
      </div>
      <p><i class="fa fa-chevron-right"></i> <a :href="this.$t('backToOriginUrl')">{{ $t('backToOrigin') }}</a></p>
    </div>

    <div v-if="asyncState === 'pending'" class="loading-wrapper">
      <div class="lds-container">
        <div class="lds-dual-ring"></div>
        <h2>{{ $t('loading') }}</h2>
      </div>
    </div>

    <br/>
    <form v-show="debug" ref="payment_form" :action="this.payment.url"
          method="post"
          style="margin-top: 3em; padding: 1em; border: 1px dashed; min-height: 200px;">
      <h3>Payment-Debug-Infos:</h3>
      <input v-for="(param, pkey) in payment.params" v-bind:key="pkey" :name="param.name" :value="param.value"
             type="text"/>
      <!-- <input id="countryCode" name="countryCode" value="CN" /> does not work - makes merchant sig invalid -->

      <!-- <input type="text" name="city" :value="form.ort" />
      <input type="text" name="country" :value="form.land" />
      <input type="text" name="street" :value="form.strasse" />
      <input type="text" name="postalCode" :value="form.plz" /> -->

      <br/>
      <!-- <button type="submit">Zahltag!</button> -->
    </form>

  </div>
</template>

<script>
import {getCourse, postOrder} from '../api';
import {capitalize} from '@/helper/Capitalize';
import {getCookie, setCookie} from '@/helper/Cookies';
// import Session from './Session';
import Warenkorb from './Warenkorb';
import Lieferadresse from './Lieferadresse';
import Zusammenfassung from './Zusammenfassung';

export default {
  name: 'FormWizard',
  components: {
    // Session,
    Warenkorb,
    Lieferadresse,
    Zusammenfassung,
  },
  data() {
    return {
      version: '20210125-1435',
      debugmsg: [],
      debug: false,
      referer: null,

      init: true,
      abortProcess: false,
      showNavButtons: true,
      currentStepNumber: 1,
      e1: 0,
      asyncState: null,
      paymentState: null,
      steps: [
        // 'Session',
        'Warenkorb',
        'Lieferadresse',
        'Zusammenfassung',
      ],
      stepTitles: [  // TODO: include in steps
        // 'Datenschutzkonferenz. Aspekte Informationssicherheit und Cloud',
        this.$t('cartHeader'),
        this.$t('billingHeader'),
      ],
      payment: {
        url: '',
        id_trans: '',
        params: [],
      },
      form: {
        version: '20191030-1130',

        doceboCountry: null,
        micrositeLanguage: null,

        activeProduct: null,

        /* cart: {
          0: {
            title: 'Praxisforum zur 42. BImSchV - Hygiene beim Betrieb von Verdunstungskühlanlagen',
            subtitle: '42. BImSchV / VDI 2047-2 – Was Sie als Betreiber wissen müssen!',
            date: '12.12.2019',
            location: 'Köln',
            nettoPerPerson: 1000.00,
            hasVat: true,
            participants: [],
          },
        }, */
        cart: {},

        anrede: null,
        vorname: null,
        nachname: null,
        geburtsdatum: null,
        plz: null,
        ort: null,
        land: null,
        strasse: null,
        hausnummer: null,
        telefon_vorwahl: null,
        telefon: null,
        email: null,

        // anrede: null,
        abweichendeRng: false,
        rngVorname: null,
        rngNachname: null,
        rngName: null,
        // geburtsdatum: null,
        rngPlz: null,
        rngOrt: null,
        rngLand: null,
        rngVatId: null,
        rngStrasse: null,
        rngHausnummer: null,
        rngTelefon_vorwahl: null,
        rngTelefon: null,
        rngEmail: null,

        isCompany: false,
        companyName: null,
        companyIndustry: null,
        companyStreet: null,
        companyZip: null,
        companyCity: null,
        companyCountry: null,
        companyDepartment: null,
        companyVatId: null,

        plan: null,
        session: null,
        // email: null,
        name: null,
        address: null,

        semNr: '',
        semBeginn: '',
        semOrt: '',
      },
    };
  },
  computed: {
    isLastStep() {
      return this.currentStepNumber === this.length;
    },
    wizardInProgress() {
      return this.currentStepNumber <= this.length;
    },
    length() {
      return this.steps.length;
    },
    currentStep() {
      return this.steps[this.currentStepNumber - 1];
    },
    progress() {
      return this.currentStepNumber / this.length * 100;
    },
  },
  methods: {
    updateAsyncState(state) {
      this.asyncState = state;
    },
    /* productActivated(product) {
      this.asyncState = 'pending';
      getCourse(product.courseID).then(response => {

        console.log('asked api for course');
        console.log('response', response);
        console.log(this.form);

        this.form.cart[product.courseID].sessions = response.sessions;
        this.form.activeProduct = this.form.cart[product.courseID];
        // this.init = false

        this.currentStepNumber = 1;
        this.e1 = 1;

        this.asyncState = 'success';

        this.stepTitles[0] = this.form.activeProduct.name;
        this.$emit('titlechange', this.stepTitles[this.currentStepNumber - 1]);
      });
    }, */
    submitOrder() {
      this.asyncState = 'pending';

      this.$gtag.event('conversion', {
        'send_to': 'AW-1054537232/0aLmCMyT-L8BEJDs6_YD',
        'transaction_id': ''
      });

      postOrder(this.form).then(response => {
        // console.log('Order submitted. Response: ', response);
        this.debugmsg.push('Response: ' + JSON.stringify(response, null, 4));
        this.asyncState = 'success';
        this.currentStepNumber++;

        if (response.data.payment_url === "DOCEBO_WIRE") {
          this.paymentState = "paid";
          this.pending = false;
          localStorage.removeItem('tra_checkout_cart');
          this.quit();
        } else {
          // call adyen
          this.payment.params = response.data.payment_params;
          this.payment.id_trans = response.data.payment_id_trans;
          this.payment.url = response.data.payment_url;

          this.$nextTick(() => {
            this.$refs.payment_form.submit();
          });
        }

      });
    },
    nextButtonAction() {
      this.$refs.currentStep.submit().then(stepData => {
        Object.assign(this.form, stepData);
        if (this.isLastStep) {
          this.submitOrder();
        } else {
          setCookie('cartItemCount', Object.keys(this.form.cart).length, 7);
          this.debugmsg.push('cartItemCount (Cookie) = ' + getCookie('cartItemCount'));
          this.save();

          this.goNext();
        }
      }); /* .catch(error => console.log(error)); */
    },
    goBack() {
      this.currentStepNumber--;
      this.e1--;

      this.$emit('titlechange', this.stepTitles[this.currentStepNumber - 1]);
    },
    goNext() {
      this.currentStepNumber++;
      this.e1++;

      this.$emit('titlechange', this.stepTitles[this.currentStepNumber - 1]);
    },
    goToStepNo(stepNumber, inProgress = true) {
      this.wizardInProgress = inProgress;
      this.currentStepNumber = stepNumber;
      this.e1 = stepNumber;
    },
    goToOrigin() {
      this.save();
      window.location.href = this.$t('backToOriginUrl');
    },
    quit() {
      this.currentStepNumber = this.length + 1;
    },
    setShowNavButtons(tf) {
      this.showNavButtons = tf;
      this.save();
    },
    save() {
      // this.form.cart.version = getStoreVersion();
      const parsed = JSON.stringify(this.form.cart)
      localStorage.setItem('tra_checkout_cart', parsed)
      setCookie('cartItemCount', Object.keys(this.form.cart).length, 7)
      this.debugmsg.push('[FW saved] cartItemCount (Cookie) = ' + getCookie('cartItemCount'))
    },
    load: async (self, course_hash) => {
      let store = {}
      if (localStorage.getItem('tra_checkout_cart')) {
        try {
          store = JSON.parse(localStorage.getItem('tra_checkout_cart'))
        } catch (e) {
          self.debugmsg.push("Nothing to load from store.")
          localStorage.removeItem('tra_checkout_cart')
        }
      }

      if (!(course_hash in store)) {
        store[course_hash] = {}
      }
      for (let child in store) {
        let cid = child.split('_')[0];
        let sid = child.split('_')[1];
        if (!(isNaN(cid))) {
          let response = await getCourse(cid)
          let course = response.data
          let participants = []
          if (store[child].hasOwnProperty('participants')) {
            participants = store[child].participants
          }
          if (course.sessions.length > 1) {
            /* store[child].price = course.price
            store[child].price_vat_excl = course.price_vat_excl
            store[child].price_vat_incl = course.price_vat_incl */
            let session = course.sessions.find(session => session.id.toString() === sid);
            if (session) {
              self.form.cart[child] = course
              self.form.cart[child].selectedSession = session
              self.debugmsg.push(`Added ${child} to cart`)
            } else {
              self.debugmsg.push(`Session ${sid} for course ${cid} not found! Omitted.`)
            }
          } else {
            self.form.cart[child] = course
            self.form.cart[child].selectedSession = course.sessions[0]
            self.debugmsg.push(`Added ${child} to cart (E-Learning)`)
            // this.form.activeProduct.selectedSession = response.sessions[0];
          }
          if (child in self.form.cart) {
            self.form.cart[child].courseID = cid
            self.form.cart[child].participants = participants
          }
          self.$forceUpdate();
        }
      }

      //this.form.cart = store;
      self.$forceUpdate();
      //this.stepTitles[0] = this.form.activeProduct.name;
      self.$emit('titlechange', self.stepTitles[0]);
      self.save();

      self.init = false;
      self.asyncState = 'success';
    },
  },
  created() {
    this.debugmsg.push(`APP version ${this.version} created.`);
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.debug = params.get('debug') !== null;

    // const referer = document.referrer;
    // this.referer = referer;
    this.referer = 'https://www.tuv.com/landingpage/en/tuv-rheinland-academy/main-navigation/business-solutions/digital-learning.html';

    /* if (referer === '' && !this.debug){
      this.abortProcess = true;
      this.referer = 'https://academy.tuv.com';
    } else { */

    let country = params.get('country') != null ? params.get('country') : 'en';
    country = (country == 'greater_china' || country == 'greater-china') ? 'china' : country;

    // E-Payment response handling
    const payment_state = params.get('ps') != null ? params.get('ps') : '';
    if (['paid', 'failed'].indexOf(payment_state) >= 0) {
      this.paymentState = payment_state;
      this.currentStepNumber = this.length + 1;
      this.pending = false;
      if (localStorage.getItem('tra_checkout_billing')) {
        try {
          const billing_data = JSON.parse(localStorage.getItem('tra_checkout_billing'));
          country = billing_data['doceboCountry'];
        } catch (e) {
          localStorage.removeItem('tra_checkout_billing');
        }
      }

      if (this.paymentState === 'paid') {
        localStorage.removeItem('tra_checkout_cart');
      }
    }
    // TODO: what of the things below are still necessary on final confirmation page?

    this.form.doceboCountry = country;
    this.form.micrositeLanguage = this.referer.toLowerCase().includes('/lp/') ?
        this.referer
            .replace('://', '')
            .split('/')[2]
        : false;

    this.form.land = capitalize(country);
    this.$i18n.locale = country.toLowerCase();

    const course_id = parseInt(params.get('course'));
    const session_id = parseInt(params.get('session'));
    let course_hash = `${course_id}_${session_id}`;

    let cartItemCount = getCookie('cartItemCount');
    if (cartItemCount === '') {
      setCookie('cartItemCount', 1, 7);
      cartItemCount = getCookie('cartItemCount');
    }

    this.debugmsg.push('Initialized with: Country: ' + country + ', Course-ID: ' + course_id + ', Session-ID: ' + session_id +
        ', cartItemCount-Cookie: ' + cartItemCount);

    this.asyncState = 'pending';
    this.load(this, course_hash);

    /* let cart_sessions = [];
    try {
      for (let course_key of Object.keys(this.form.cart)) {
        cart_sessions.push(`${course_key}_${this.form.cart[course_key].selectedSession.id}`);
      }
    } catch (e) {
      console.log("[ERR] Reading selected sessions in cart failed.");
    } */
  },
  mounted() {
    this.$root.$on('saveCart', () => {
      this.save();
    });
  },
};
</script>

<style>
#debug {
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
}

.buttons {
  /*padding-top: 2rem;*/
  padding-bottom: 2rem;
}

.v-stepper {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background-color: transparent;
}

div.v-stepper__step.v-stepper__step--active > span.v-stepper__step__step {
  background-color: #0071b9;
}

</style>