export const countryRegion = {
    'algeria': 'IMEA',
    'argentina': 'SAM',
    'bulgaria': 'CEE',
    'chile': 'SAM',
    'china': 'GC',
    'colombia': 'SAM',
    'egypt': 'IMEA',
    'france': 'WEU',
    'germany': 'GOP',
    'hungary': 'CEE',
    'india': 'IMEA',
    'indonesia': 'APA',
    'mexico': 'NAM',
    'morocco': 'IMEA',
    'peru': 'SAM',
    'philippines': 'APA',
    'poland': 'CEE',
    'portugal': 'WEU',
    'romania': 'CEE',
    'saudi-arabia': 'IMEA',
    'singapore': 'APA',
    'spain': 'WEU',
    'thailand': 'APA',
    'tunisia': 'IMEA',
    'united-arab-emirates': 'IMEA',
    'vietnam': 'APA'
};