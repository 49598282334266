<template>
    <div>
        <h1 class="title">{{ $t("cartHeader") }}</h1>

        <div v-if="cartItemCount === 0">
            <p>{{ $t("cartIsEmpty") }}</p>
            <p><i class="fa fa-chevron-right"></i>&nbsp;<a :href="this.$t('backToOriginUrl')">{{
                $t('backToOrigin')
              }}</a></p>
        </div>

        <form class="form">

            <div v-for="(product, pkey) in cart" :key="pkey"
                 class="component tuv-service__item accordion-component clearfix" style="">
              <h3 class="accordion-header h-show-mobile">{{ product.name }}&nbsp;</h3>
              <div class="accordion-content bc-show">
                <div class="tuv-service__img-box bc-cart-item-image h-hidden-mobile">
                  <!-- <img src="../assets/img/bc/TUV_R_Icons_Online_Shop_RGB.svg" alt="Checkout Icon"> -->
                  <svg id="Layout" style="enable-background:new -86.3 340 141.9 122.4;" version="1.1"
                       viewBox="-86.3 340 141.9 122.4" x="0px" xml:space="preserve"
                       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                       y="0px">
                            <!-- <style type="text/css">
                                .st0 {
                                    fill: #0071B9;
                                }
                            </style> -->
                            <g id="XMLID_428_">
                                <path class="st0" d="M-48,356.8l4.4,10.4h84.3l-16.8,40.9c-3.7-0.6-8.3-1.2-12.8-1.2c-9.3,0-18.3,2.3-19.3,2.6
                                        c-0.1,0-7.8,2.1-15.5,2.1c-5,0-10-0.9-12.9-1.5L-65.9,340h-20.4v10.4h13.9l28.6,68.6l2.1,0.5c0.4,0.1,9,2.4,17.9,2.4
                                        s17.6-2.3,17.9-2.4c0.4-0.1,8.7-2.2,16.9-2.2c8.3,0,17.5,2.3,17.6,2.4l1.1,0.3l25.9-63.2H-48z"
                                      id="XMLID_435_"/>
                                <path class="st0" d="M-38.1,441.6c2.7,0,5,2.3,5,5.2c0,2.9-2.2,5.2-5,5.2c-2.7,0-5-2.3-5-5.2
                                        C-43.1,443.9-40.8,441.6-38.1,441.6 M-38.1,431.2c-8.2,0-14.9,7-14.9,15.6c0,8.6,6.7,15.6,14.9,15.6s14.9-7,14.9-15.6
                                        C-23.2,438.2-29.9,431.2-38.1,431.2L-38.1,431.2z" id="XMLID_432_"/>
                                <path class="st0" d="M23.6,441.6c2.7,0,5,2.3,5,5.2c0,2.9-2.2,5.2-5,5.2c-2.7,0-5-2.3-5-5.2
                                        C18.6,443.9,20.9,441.6,23.6,441.6 M23.6,431.2c-8.2,0-14.9,7-14.9,15.6c0,8.6,6.7,15.6,14.9,15.6s14.9-7,14.9-15.6
                                        C38.5,438.2,31.8,431.2,23.6,431.2L23.6,431.2z" id="XMLID_429_"/>
                            </g>
                        </svg>
                    </div>
                    <div class="tuv-service__text-box">
                        <h3 class="accordion-header h-hidden-mobile">
                            <!--CORE-873-->
                            <span class="tuv-teaser__headline">{{ product.name }}&nbsp;
                                <!-- <span class="inner-icon fa fa-chevron-right"></span> -->
                            </span>
                        </h3>
                        <div v-html="product.description"></div>
                        <table>
                            <tr>
                                <td><span class="inner-icon fa fa-calendar" /></td>
                                <td>{{ product.selectedSession.start_date | formatDate('en') }} - {{ product.selectedSession.end_date | formatDate('en') }}</td>
                            </tr>
                            <tr>
                                <td><span class="inner-icon fa fa-map-marker"/></td>
                                <td>{{ product.selectedSession.location.name }}</td>
                            </tr>
                        </table>
                        <!--CORE-89--->
                        <!-- <a href="#" class="tuv-btn tuv-btn--secondary h-show-mobile">{{ $t("more") }}</a> -->

                        <!-- TN -->
                        <br />
                      <div v-for="(guy, index) in product.participants" :key="index"
                           class="emp-form-page emp-form-page-active">
                        <h3>{{ $t("participantNumber") }} {{ index + 1 }}:</h3>
                        <div class="tuv-col-box">
                          <div class="col">
                            <div class="form_element">
                              <div class="tuv-input">
                                <label class="tuv-input__label" for="firstName">{{ $t("firstName") }}
                                  *</label>
                                <div class="input-container" data-module="reset-input">
                                  <input class="tuv-input__control" id="firstName" name="firstName"
                                         placeholder="" type="text" v-model="guy.firstName">
                                                <a href="#" class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times" style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">{{ $t("pleaseProvideInfo") }}</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label class="tuv-input__label" for="lastName">{{ $t("lastName") }}
                                                *</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input class="tuv-input__control" id="lastName" name="lastName"
                                                       placeholder="" type="text" v-model="guy.lastName">
                                                <a href="#" class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times" style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">{{ $t("pleaseProvideInfo") }}</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form_element">
                                <div class="tuv-input">
                                    <label for="input" class="tuv-input__label">Company *</label>
                                    <div class="input-container" data-module="reset-input">
                                        <input type="text" id="input" class="tuv-input__control" name="input" placeholder="">
                                        <a href="#" class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times" style="display: none;"></a>
                                    </div>
                                    <span class="tuv-input__error-message">Please enter your Company</span>
                                </div>
                                <br>
                            </div> -->
                            <div class="tuv-col-box">
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label class="tuv-input__label" for="email">{{ $t("email") }} *</label>
                                            <div class="input-container" data-module="reset-input">
                                              <input
                                                  id="email"
                                                  v-model="guy.email"
                                                  class="tuv-input__control"
                                                  name="email"
                                                  placeholder=""
                                                  type="text"
                                                  @blur="isEmailAbleToBook(guy)">
                                              <a href="#"
                                                 class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times"
                                                 style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">{{ $t("pleaseProvideEmail") }}</span>
                                            <span v-if="!guy.canBook" style="color: orangered; font-size: 0.8em;">Warning: This E-Mail address might currently not be able to book a course in this country.</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label class="tuv-input__label" for="position">{{ $t("jobTitle") }}</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input class="tuv-input__control" id="position" name="position"
                                                       placeholder="" type="text" v-model="guy.position">
                                                <a href="#" class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times" style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">{{ $t("pleaseProvideInfo") }}</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                            <div class="tuv-col-box">
                                <!-- <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label for="input" class="tuv-input__label">Birthday</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input v-model="guy.birthday" type="text" id="input" class="tuv-input__control" name="input" placeholder="">
                                                <a href="#" class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times" style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">Please enter your Birthday</span>
                                        </div>
                                        <br>
                                    </div>
                                </div> -->
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label class="tuv-input__label" for="phone">{{ $t("phone") }}</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input class="tuv-input__control" id="phone" name="phone" placeholder=""
                                                       type="text" v-model="guy.phone">
                                                <a href="#" class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times" style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">{{ $t("pleaseProvideInfo") }}</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <div class="input-container" data-module="reset-input">
                                                <label class="tuv-input__label">&nbsp;
                                                    <!-- {{ $t("actions") }}--></label>
                                                <div>
                                                    <button
                                                            @click.prevent="removeParticipant(product, index)"
                                                            class="tuv-btn--secondary"
                                                    ><i class="fa fa-trash"></i> {{ $t("deleteAttendee") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tuv-container">
                            <h3>{{ $t("price") }}:</h3>
                            <table>
                                <tr>
                                    <td>{{ $t("numberParticipants") }}:</td>
                                    <td style="text-align: right">{{ product.participants.length }}</td>
                                </tr>

                                <tr>
                                    <td>{{ $t("coursePricePerAttendee") }}:</td>
                                    <td style="text-align: right">
                                        <span v-if="product.price_vat_incl > 0">{{ $n(product.price_vat_incl, 'currency', $t("langCode")) }} {{ $t("vatIncl") }}</span>
                                        <span v-if="product.price_vat_incl > 0 && product.price_vat_excl > 0">, </span>
                                        <span v-if="product.price_vat_excl > 0">{{ $n(product.price_vat_excl, 'currency', $t("langCode")) }} {{ $t("vatExcl") }}</span>
                                    </td>
                                </tr>
                                <tr v-if="product.exam_fee_vat_incl > 0 || product.exam_fee_vat_excl > 0">
                                    <td>{{ $t("examFeePerAttendee") }}:</td>
                                    <td style="text-align: right">
                                        <span v-if="product.exam_fee_vat_incl > 0">{{ $n(product.exam_fee_vat_incl, 'currency', $t("langCode")) }} {{ $t("vatIncl") }}</span>
                                        <span v-if="product.exam_fee_vat_incl > 0 && product.exam_fee_vat_excl > 0">, </span>
                                        <span v-if="product.exam_fee_vat_excl > 0">{{ $n(product.exam_fee_vat_excl, 'currency', $t("langCode")) }} {{ $t("vatExcl") }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t("total") }}:</td>
                                    <td style="text-align: right">
                                        <span v-if="product.price_vat_incl + product.exam_fee_vat_incl > 0">{{ $n( (product.price_vat_incl + product.exam_fee_vat_incl) * product.participants.length, 'currency', $t("langCode")) }} {{ $t("vatIncl") }}</span>
                                        <span v-if="product.price_vat_incl + product.exam_fee_vat_incl > 0 && product.price_vat_excl + product.exam_fee_vat_excl > 0">, </span>
                                        <span v-if="product.price_vat_excl + product.exam_fee_vat_excl > 0">{{ $n((product.price_vat_excl + product.exam_fee_vat_excl) * product.participants.length, 'currency', $t("langCode")) }} {{ $t("vatExcl") }}</span>
                                    </td>
                                </tr>
                                <!--
                                <tr v-if="product.price_vat_incl > 0 && product.price_vat_excl > 0">
                                    <td>Course-price per attendee (VAT incl.):</td>
                                    <td style="text-align: right">{{ $n(product.price_vat_incl, 'currency', $t("langCode")) }} <span class="grey--text">({{ $t("vatExcl") }}: {{ $n(product.price_vat_excl, 'currency', $t("langCode")) }})</span></td>
                                </tr>
                                <tr v-if="product.price_vat_incl === 0 && product.price_vat_excl > 0">
                                    <td>Course-price per attendee (VAT-exempt):</td>
                                    <td style="text-align: right">{{ $n(product.price_vat_excl, 'currency', $t("langCode")) }}</td>
                                </tr>
                                <tr v-if="product.exam_fee_vat_incl > 0 && product.exam_fee_vat_excl > 0">
                                    <td>Exam fee per participant:</td>
                                    <td style="text-align: right">{{ $n(product.exam_fee_vat_incl, 'currency', $t("langCode")) }} <span class="grey--text">({{ $t("vatExcl") }}: {{ $n(product.exam_fee_vat_excl, 'currency', $t("langCode")) }})</span></td>
                                </tr>
                                <tr v-if="product.exam_fee_vat_incl === 0 && product.exam_fee_vat_excl > 0">
                                    <td>Exam fee per participant (VAT-exempt):</td>
                                    <td style="text-align: right">{{ $n(product.exam_fee_vat_excl, 'currency', $t("langCode")) }}</td>
                                </tr>
                                <tr v-if="product.price_vat_incl > 0 && product.exam_fee_vat_incl > 0">
                                    <td>{{ $t("priceTotalVatIncl") }}:</td>
                                    <td style="text-align: right">{{ $n((product.price_vat_incl + product.exam_fee_vat_incl) * product.participants.length, 'currency', $t("langCode")) }} <span class="grey--text">({{ $t("vatExcl") }}: {{ $n((product.price_vat_excl + product.exam_fee_vat_excl) * product.participants.length, 'currency', $t("langCode")) }})</span></td>
                                </tr>
                                <tr v-if="product.price_vat_incl === 0 || product.exam_fee_vat_incl === 0">
                                    <td>{{ $t("total") }}:</td>
                                    <td style="text-align: right">{{ $n((product.price_vat_excl + product.exam_fee_vat_excl) * product.participants.length, 'currency', $t("langCode")) }} ({{ $t("vatExcl") }})</td>
                                </tr>
                                -->
                            </table>
                        </div>

                        <!-- <div class="tuv-btn--right">
                            &nbsp;<button
                                    class="tuv-btn--gunmetal"
                            >Löschen <i class="fa fa-trash"></i>
                            </button>&nbsp;
                        </div> -->
                        <div class="tuv-btn--right">
                            <button
                                    @click.prevent="removeProduct(product, pkey)"
                                    class="tuv-btn--secondary"
                            ><i class="fa fa-trash"></i> {{ $t("deleteProduct") }}
                            </button>

                            <button @click.prevent="addParticipant(product)"
                                    class="tuv-btn--right"
                            ><i class="fa fa-user-plus"></i> {{ $t("addAttendee") }}
                            </button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="component">
                <h2>{{ $t("yourTotal") }}</h2>
                <table>
                    <tr>
                        <td style="width: 80%;">{{ $t("priceVatExcl") }}:</td>
                        <td>{{ $n(netto_price, 'currency', $t("langCode")) }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("vat") }}:</td>
                        <td>{{ $n(mwst, 'currency', $t("langCode")) }}</td>
                    </tr>
                    <tr style="font-size: 1.5rem;">
                        <td>{{ $t("total") }}:</td>
                        <td>{{ $n(total, 'currency', $t("langCode")) }}</td>
                    </tr>
                </table>
            </div> -->

            <div class="tuv-container">
                <span v-if="$v.$invalid && cartItemCount > 0" class="bc-tuv-form__error-message right">{{ $t("pleaseAddAttendee") }}</span>
            </div>

            <!--<div class="row">
                <div class="col-md-9">
                    <v-card
                            light
                            max-width="600">
                        <v-card-title style="background-color: #337ab7;"><span class="title font-weight-light">{{wizardData.semNr}}</span></v-card-title>
                        <v-card-text style="margin-bottom: 2rem;">
                            <p>am {{ wizardData.semBeginn | formatDate }} in {{ wizardData.semOrt }}</p>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p></v-card-text>
                        <v-card-actions>
                            <v-list-tile class="grow">
                                <v-list-tile-content>
                                    <v-list-tile-title>Teilnehmer: </v-list-tile-title>
                                </v-list-tile-content>

                                <v-layout
                                        align-center
                                        justify-end
                                >
                                    <v-slider v-model="tn" min="1" max="10" thumb-label="always"></v-slider>
                                </v-layout>
                            </v-list-tile>
                        </v-card-actions>
                    </v-card>
                </div>

                <div class="col-md-3">
                    <h2>Ihre Investition</h2>
                    <table>
                        <tr>
                            <td style="width: 70%;">Betrag netto:</td>
                            <td>{{ netto_price }} €</td>
                        </tr>
                        <tr>
                            <td>MwSt.:</td>
                            <td>{{ mwst }} €</td>
                        </tr>
                        <tr style="font-size: 1.5rem;">
                            <td>Summe:</td>
                            <td>{{ total }} €</td>
                        </tr>
                    </table>
                </div>
            </div> -->
        </form>

    </div>
</template>

<script>
import {email, required} from 'vuelidate/lib/validators';
import {setCookie} from '@/helper/Cookies';
import applicationTracking from './../plugins/webtrekk';
import {countryRegion} from './../helper/wtMappings';
import {numberFormats} from './../helper/NumberFormats';
import {getUserCanBook} from "@/api";

export default {
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cart: null,
      cartItemCount: 0,
        activeParticipant: {
          firstName: null,
          lastName: null,
          email: '',
          position: '',
          birthday: '',
          phone: '',
          obj: null,
        },
        form: {
          plz: '',
          ort: '', // "Schutterwald",
          strasse: '', //"Bahnhofstr.",
          hausnummer: '', // "1",

          //address: null,
          //recipient: null,
        },
        cities: [],
      selectedCity: null,
      address: '',

      tn: 1,
      netto_price: 0,
      mwst: 0,
      total: 0,
      // selectedPlan: null,
    };
  },
  mounted: function () {
    // let wtContentId = `[${this.$i18n.locale}|${this.$t('langCode')}].[Checkout Step 1]`;
    let wtContentId = `[${this.$i18n.locale}|${this.wizardData.micrositeLanguage}].[Checkout Step 1]`;
    let wtData = {
      category: {
        // 1: `${this.$t('langCode')}|${this.$i18n.locale}`,  // Country | Language (CG 1): language|country - Bsp. "en|india"
        1: `${this.wizardData.micrositeLanguage}|${this.$i18n.locale}`,
        // 7: `[${this.$i18n.locale}|${this.$t('langCode')}].[Checkout Step 1]`,  // English page name (CG7): [country|language].[page name] - Bsp. "[spain|es].[Checkout Step 1]"
        7: `[${this.$i18n.locale}|${this.wizardData.micrositeLanguage}].[Checkout Step 1]`,
        14: 'docebo checkout',  // Page Type (CG 14)
      },
      parameter: {
        1: 'A',  // TUV Business Stream (PP 1)
        2: 'A.02 Training and HR Development',  // TUV Business Field (PP 2)
        3: 'no Service ID',  // Service ID (PP 3)
        4: `${this.$i18n.locale}`,  // TUV Country Website (PP 4)
        5: countryRegion[this.$i18n.locale] || '',  // TUV Region (PP 5)
        7: 'docebo',  // Platform (PP 7)
        13: '',  // B2B/ B2C (PP 13)
        14: 'no login status',  // Login Status (PP 14)
      },
    };
    applicationTracking.page(wtContentId, wtData);
    applicationTracking.trackPage();
  },
  computed: {
    referer() {
      return document.referrer;
    },
  },
  validations: {
    // tn: {required, integer},
    /* form: {
      plz: {required, integer},
      ort: {required},
      strasse: {required},
      hausnummer: {required},
      verbrauch: {required, integer},
    }, */
      cart: {
        $each: {
          participants: {
            required,
            $each: {
              firstName: {required},
              lastName: {required},
              email: {required, email},
            }
          }
        }
      }
      // address: {required, AddressValidator},
    },
    watch: {
      cart: {
        handler() {
          let total_netto = 0;
          for (let index in this.cart) {
            total_netto += (this.cart[index].price_vat_excl + this.cart[index].exam_fee_vat_excl) * this.cart[index].participants.length
          }
          this.netto_price = total_netto.toFixed(2);

          let total_brutto = 0;
          for (let index in this.cart) {
            total_brutto += (this.cart[index].price_vat_incl + this.cart[index].exam_fee_vat_incl) * this.cart[index].participants.length
          }

          this.mwst = (total_brutto - total_netto).toFixed(2); //(this.netto_price * 0.19).toFixed(2)
          this.total = total_brutto.toFixed(2); //(this.netto_price * 1.19).toFixed(2)

          this.cartItemCount = Object.keys(this.cart).length;
          if (this.cartItemCount === 0) {
            this.$emit('setShowNavButtons', false);
          }
        },
        deep: true
      },
      tn(value) {
        this.netto_price = (this.wizardData.semPreis * value).toFixed(2);
        this.mwst = (this.netto_price * 0.19).toFixed(2);
        this.total = (this.netto_price * 1.19).toFixed(2)
      },
    },
    methods: {
      isEmailAbleToBook(participant) {
        const re_mail = /\S+@\S+\.\S+/
        let email = participant.email
        if (email.length > 0 && re_mail.test(email)) {
          getUserCanBook(email, this.wizardData.doceboCountry).then(response => {
            participant.canBook = response.data.can_book
          });
        }
      },
      addParticipant(product) {
        product.participants.push({
          firstName: null,
          lastName: null,
          position: '',
          //birthday: '01.04.1980',
          phone: '',
          email: '',
          canBook: true
        })
      },
      removeParticipant(product, participant) {
        product.participants.splice(participant, 1);
        this.save();
      },
      removeProduct(product, key) {
        //delete this.cart[key];
        this.$delete(this.cart, key);
        this.$forceUpdate();
        this.cartItemCount = Object.keys(this.cart).length;
        if (this.cartItemCount === 0) {
          this.$emit('setShowNavButtons', false);
        }
        this.save();
      },
      selectEvent(product){
        this.$emit('productActivated', product)
      },
      save() {
        // this.form.cart.version = getStoreVersion();
        const parsed = JSON.stringify(this.cart);
        localStorage.setItem('tra_checkout_cart', parsed);
        setCookie('cartItemCount', Object.keys(this.cart).length, 7);
      },
      submit() {
        this.$v.$touch();
        return new Promise((resolve, reject) => {
          if (!this.$v.$invalid) {
            resolve({
              plz: this.form.plz,
              ort: this.form.ort,
              strasse: this.form.strasse,
              hausnummer: this.form.hausnummer,
              vorjahresverbrauch: this.form.verbrauch,
              energie: this.form.energie,
              kundentyp: this.form.kundentyp,

              //recipient: this.form.recipient,
              //address: this.form.address
            });

            let wtProducts = [];
            for (let pid in this.cart) {
              let product = this.cart[pid];
              let productPrice = product.price_vat_incl + product.exam_fee_vat_incl > 0 ? product.price_vat_incl + product.exam_fee_vat_incl : product.price_vat_excl + product.exam_fee_vat_excl;
              let wtProduct = {
                id: `${product.name}, ${product.courseID}`,
                cost: productPrice,
                currency: numberFormats[this.$t('langCode')].currency.currency,
                quantity: product.participants.length,
                /* parameter: {
                  1: 'L'
                }, */
                category: {
                  1: product.category
                }
              };
              wtProducts.push(wtProduct);
            }
            applicationTracking.setBasket(wtProducts);
            applicationTracking.track();

          } else {
            reject('invalid address');
          }
        });
      },
    },
  activated() {
    this.netto_price = this.wizardData.semPreis;
    this.mwst = this.netto_price * 0.19;
    this.total = this.netto_price * 1.19;

    this.cart = this.wizardData.cart;
    this.cartItemCount = Object.keys(this.cart).length;
    // this.$forceUpdate();
    // this.$v.$touch();
  },
  components: {
    // VueGoogleAutocomplete

  },
  };
</script>

<style>
    .cart-item {
        border: 1px solid #ccc;
        padding: 25px 19px 40px 80px;
        position: relative;
        background: #fff;
    }

    td {
        padding-right: 0.5rem;
    }

    svg > * > .st0 {
        fill: #0071B9;
    }
</style>