export const numberFormats = {
  'en': {
    currency: {
      style: 'currency', currency: 'USD'
    },
  },
  'es': {
    currency: {
      style: 'currency', currency: 'EUR'
    },
  },
  'fr': {
    currency: {
      style: 'currency', currency: 'EUR'
    },
  },
  'bg': {
    currency: {
      style: 'currency', currency: 'BGN'
    },
  },
  'hu': {
    currency: {
      style: 'currency', currency: 'HUF'
    },
  },
  'pt': {
    currency: {
      style: 'currency', currency: 'EUR'
    },
  },
  'ro': {
    currency: {
      style: 'currency', currency: 'EUR'
    },
  },
  'bo': {
    currency: {
      style: 'currency', currency: 'BOB'
    },
  },
  'ae-u-nu-latn': {
    currency: {
      style: 'currency', currency: 'AED',
    },
  },
  'zh': {
    currency: {
      style: 'currency', currency: 'CNY'
    },
  },
  'ar-DZ': {
    currency: {
      style: 'currency', currency: 'DZD'
    },
  },
  'es-CL': {
    currency: {
      style: 'currency', currency: 'CLP'
    },
  },
  'es-CO': {
    currency: {
      style: 'currency', currency: 'COP'
    },
  },
  'ar-EG-u-nu-latn': {
    currency: {
      style: 'currency', currency: 'EGP',
    },
  },
  'hi-IN': {
    currency: {
      style: 'currency', currency: 'INR'
    },
  },
  'id-ID': {
    currency: {
      style: 'currency', currency: 'IDR'
    },
  },
  'es-MX': {
    currency: {
      style: 'currency', currency: 'MXN'
    },
  },
  'ar-MA-u-nu-latn': {
    currency: {
      style: 'currency', currency: 'MAD',
    },
  },
  'es-PE': {
    currency: {
      style: 'currency', currency: 'PEN'
    },
  },
  'tl-PH': {
    currency: {
      style: 'currency', currency: 'PHP'
    },
  },
  'pl-PL': {
    currency: {
      style: 'currency', currency: 'PLN'
    },
  },
  'ar-SA-u-nu-latn': {
    currency: {
      style: 'currency', currency: 'SAR',
    },
  },
  'zh-SG': {
    currency: {
      style: 'currency', currency: 'SGD'
    },
  },
  'th': {
    currency: {
      style: 'currency', currency: 'THB'
    },
  },
    'ar-TN': {
        currency: {
            style: 'currency', currency: 'TND'
        },
    },
    'vi': {
        currency: {
            style: 'currency', currency: 'VND'
        },
    },
    'ja': {
        currency: {
            style: 'currency', currency: 'JPY'
        },
    },
};