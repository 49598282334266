<template>
    <div>
        <h1 class="title">{{ $t('summaryHeader') }}</h1>

        <!-- <h2 class="subtitle">
            Where should we send your freshly roasted coffee beans?
        </h2> -->

        <div class="component tuv-container">
            <h2>{{ $t('products') }}</h2>
            <div v-for="(product, key) in wizardData.cart" :key="key"
                 class="component tuv-service__item accordion-component clearfix"
                 style="">
              <h3 class="accordion-header h-show-mobile">{{ product.name }}&nbsp;</h3>
              <div class="accordion-content bc-show">
                <div class="tuv-service__img-box bc-cart-item-image h-hidden-mobile">
                  <div>
                    <img src="../assets/img/bc/TUV_R_Icons_Online_Shop_RGB.svg" alt="Checkout Icon">
                  </div>
                </div>
                <div class="tuv-service__text-box">
                        <h3 class="accordion-header h-hidden-mobile">
                            <!--CORE-873-->
                            <div
                                    class="tuv-teaser__headline">{{ product.name }}&nbsp;
                                <!-- <span class="inner-icon fa fa-chevron-right"></span> -->
                            </div>
                        </h3>
                        <div v-html="product.description"></div>
                        <table>
                            <tr>
                                <td><span class="inner-icon fa fa-calendar"/></td>
                                <td>{{ product.selectedSession.start_date | formatDate('en') }} - {{
                                    product.selectedSession.end_date | formatDate('en') }}
                                </td>
                            </tr>
                            <tr>
                                <td><span class="inner-icon fa fa-map-marker"/></td>
                                <td>{{ product.selectedSession.location.name }}</td>
                            </tr>
                        </table>
                        <!--CORE-89--->
                        <!-- <a href="#" class="tuv-btn tuv-btn--secondary h-show-mobile">more</a> -->

                        <!-- TN -->
                        <br/>
                  <div v-for="(guy, index) in product.participants" :key="index"
                       class="emp-form-page emp-form-page-active">
                    <h3>{{ $t('participantNumber') }} {{ index + 1 }}:</h3>
                    <div class="tuv-col-box">
                      <div class="col">
                        <div class="form_element">
                          <div class="tuv-input">
                            <label for="input" class="tuv-input__label">{{ $t('firstName') }} *</label>
                            <div class="input-container" data-module="reset-input">
                              <input disabled v-model="guy.firstName" type="text" id="input"
                                     class="tuv-input__control" name="input" placeholder="">
                              <a href="#"
                                                   class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times"
                                                   style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">Enter your first name</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label for="input" class="tuv-input__label">{{ $t('lastName') }} *</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input disabled v-model="guy.lastName" type="text" id="input"
                                                       class="tuv-input__control" name="input" placeholder="">
                                                <a href="#"
                                                   class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times"
                                                   style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">Enter your last name</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form_element">
                                <div class="tuv-input">
                                    <label for="input" class="tuv-input__label">Company *</label>
                                    <div class="input-container" data-module="reset-input">
                                        <input type="text" id="input" class="tuv-input__control" name="input" placeholder="">
                                        <a href="#" class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times" style="display: none;"></a>
                                    </div>
                                    <span class="tuv-input__error-message">Please enter your Company</span>
                                </div>
                                <br>
                            </div> -->
                            <div class="tuv-col-box">
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label for="input" class="tuv-input__label">{{ $t('email') }} *</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input disabled v-model="guy.email" type="text" id="input"
                                                       class="tuv-input__control" name="input" placeholder="">
                                                <a href="#"
                                                   class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times"
                                                   style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">Please enter your Email</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label for="input" class="tuv-input__label">{{ $t('jobTitle') }}</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input disabled v-model="guy.position" type="text" id="input"
                                                       class="tuv-input__control" name="input" placeholder="">
                                                <a href="#"
                                                   class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times"
                                                   style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">Please enter your job title</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                            <div class="tuv-col-box">
                                <!-- <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label for="input" class="tuv-input__label">Birthday</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input v-model="guy.birthday" type="text" id="input" class="tuv-input__control" name="input" placeholder="">
                                                <a href="#" class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times" style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">Please enter your Birthday</span>
                                        </div>
                                        <br>
                                    </div>
                                </div> -->
                                <div class="col">
                                    <div class="form_element">
                                        <div class="tuv-input">
                                            <label for="input" class="tuv-input__label">{{ $t('phone') }}</label>
                                            <div class="input-container" data-module="reset-input">
                                                <input disabled v-model="guy.phone" type="text" id="input"
                                                       class="tuv-input__control" name="input" placeholder="">
                                                <a href="#"
                                                   class="js-input-reset tuv-input__close-btn tuv-tooltip__close-btn fa fa-times"
                                                   style="display: none;"></a>
                                            </div>
                                            <span class="tuv-input__error-message">Please enter your phone number</span>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tuv-container">
                            <h3>{{ $t("price") }}:</h3>
                            <table>
                                <tr>
                                    <td>{{ $t("numberParticipants") }}:</td>
                                    <td style="text-align: right">{{ product.participants.length }}</td>
                                </tr>

                                <tr>
                                    <td>{{ $t("coursePricePerAttendee") }}:</td>
                                    <td style="text-align: right">
                                        <span v-if="product.price_vat_incl > 0">{{ $n(product.price_vat_incl, 'currency', $t("langCode")) }} {{ $t("vatIncl") }}</span>
                                        <span v-if="product.price_vat_incl > 0 && product.price_vat_excl > 0">, </span>
                                        <span v-if="product.price_vat_excl > 0">{{ $n(product.price_vat_excl, 'currency', $t("langCode")) }} {{ $t("vatExcl") }}</span>
                                    </td>
                                </tr>
                                <tr v-if="product.exam_fee_vat_incl > 0 || product.exam_fee_vat_excl > 0">
                                  <td>{{ $t("examFeePerAttendee") }}:</td>
                                  <td style="text-align: right">
                                    <span v-if="product.exam_fee_vat_incl > 0">{{
                                        $n(product.exam_fee_vat_incl, 'currency', $t("langCode"))
                                      }} {{ $t("vatIncl") }}</span>
                                    <span
                                        v-if="product.exam_fee_vat_incl > 0 && product.exam_fee_vat_excl > 0">, </span>
                                    <span v-if="product.exam_fee_vat_excl > 0">{{
                                        $n(product.exam_fee_vat_excl, 'currency', $t("langCode"))
                                      }} {{ $t("vatExcl") }}</span>
                                  </td>
                                </tr>
                                <tr>
                                    <td>Total:</td>
                                    <td style="text-align: right">
                                        <span v-if="product.price_vat_incl + product.exam_fee_vat_incl > 0">{{ $n( (product.price_vat_incl + product.exam_fee_vat_incl) * product.participants.length, 'currency', $t("langCode")) }} {{ $t("vatIncl") }}</span>
                                        <span v-if="product.price_vat_incl + product.exam_fee_vat_incl > 0 && product.price_vat_excl + product.exam_fee_vat_excl > 0">, </span>
                                        <span v-if="product.price_vat_excl + product.exam_fee_vat_excl > 0">{{ $n((product.price_vat_excl + product.exam_fee_vat_excl) * product.participants.length, 'currency', $t("langCode")) }} {{ $t("vatExcl") }}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            <!-- <div class="component">
                <h2>{{ $t("yourTotal") }}</h2>
                <table>
                    <tr>
                        <td style="width: 80%;">{{ $t("priceVatExcl") }}:</td>
                        <td>{{ $n(netto_price, 'currency', $t("langCode")) }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("vat") }}:</td>
                        <td>{{ $n(mwst, 'currency', $t("langCode")) }}</td>
                    </tr>
                    <tr style="font-size: 1.5rem;">
                        <td>{{ $t("total") }}:</td>
                        <td>{{ $n(total, 'currency', $t("langCode")) }}</td>
                    </tr>
                </table>
            </div> -->
        </div>

        <div class="component tuv-container">
            <h2>{{ $t('contactInformation') }}</h2>
            <div>
                <h3>{{ $t('yourPersonalContact') }}:</h3>
                <address>
                    {{wizardData.vorname}} {{wizardData.nachname}}<br/>
                    {{wizardData.strasse}} {{wizardData.hausnummer}}<br/>
                    {{wizardData.plz}} {{wizardData.ort}}<br/>
                    {{wizardData.land}}
                </address>
            </div>

            <div v-if="wizardData.isCompany">
                <br/>
                <h3>{{ $t('yourBusinessContact') }}</h3>
                <address>
                    {{wizardData.companyName}}<br/>
                    {{wizardData.companyStreet}}<br/>
                    {{wizardData.companyZip}} {{wizardData.companyCity}}<br/>
                    {{wizardData.companyCountry}}<br/>
                    {{ $t('vatId') }}: {{wizardData.companyVatId}}
                </address>
            </div>
        </div>


        <div class="component tuv-container">
            <h2>{{ $t('billing') }}</h2>
            <!-- <div v-if="!wizardData.abweichendeRng">
                <p>Billing information are identical to contact information.</p>
            </div> -->
            <!-- <div v-if="wizardData.abweichendeRng"> -->
            <address>
                {{wizardData.rngName}}<br/>
                {{wizardData.rngStrasse}} {{wizardData.rngHausnummer}}<br/>
                {{wizardData.rngPlz}} {{wizardData.rngOrt}}<br/>
                {{wizardData.rngLand}}<br/>
                <span v-if="wizardData.rngVatId !== null">{{ $t('vatId') }}: {{wizardData.rngVatId}}</span>
            </address>
            <!-- </div> -->
        </div>


        <!-- <h2>Auftragsdaten</h2>
        <table>
            <tbody>
            <tr>
                <td>Tarif: </td>
                <td style="padding-left: 1em;">{{wizardData.plan.productName}}</td>
            </tr>
            <tr>
                <td>Geschätzter Jahresverbrauch (kWh): </td>
                <td style="padding-left: 1em;">{{wizardData.vorjahresverbrauch}}</td>
            </tr>
            <tr>
                <td>Voraussichtliche Gesamtkosten pro Jahr: </td>
                <td style="padding-left: 1em;">{{wizardData.plan.totalBasePriceBrutto}}</td>
            </tr>
            </tbody>
        </table>

        <h2>Rechnungsadresse</h2>
        <div v-if="!wizardData.abweichendeRng">
            <p>Die Rechnungadresse entspricht der Lieferadresse.</p>
        </div>
        <div v-if="wizardData.abweichendeRng">
            <address>
                {{wizardData.rngVorname}} {{wizardData.rngNachname}}<br />
                {{wizardData.rngStrasse}} {{wizardData.rngHausnummer}}<br />
                {{wizardData.rngPlz}} {{wizardData.rngOrt}}
            </address>
        </div> -->

        <h2>{{ $t('legal') }}</h2>
        <div class="component tuv-container">
            <ul class="tuv-check-list">
                <li>
                    <label class="tuv-checkbox ">
                        <input v-model="form.acceptLegal" @change="$v.form.acceptLegal.$touch()" type="checkbox"
                               class="tuv-checkbox__control" name="checkbox">
                        <span class="tuv-checkbox__icon fa fa-check"></span>
                        <span class="tuv-checkbox__label">{{ $t('iAcceptLegalTerms') }} <a :href="getLegalUrl()"
                                                                                           target="_blank">{{ $t('readLegalTerms') }}</a></span>
                        <span v-if="$v.form.acceptLegal.$error" class="bc-tuv-form__error-message">{{ $t('pleaseAcceptLegalTerms') }}</span>
                    </label>
                </li>
            </ul>
        </div>

        <!--
        <div>
            <input id="kontakt" type="checkbox" v-model="form.kontakt" @change="$v.form.agb.$touch()"/>
            <label for="kontakt">&nbsp;Hiermit erlaube ich der ... GmbH, mich zum Zwecke der Leistungserfüllung zu kontaktieren.</label>
            <div v-if="$v.form.kontakt.$error" class="alert alert-danger">Bitte geben Sie uns die Erlaubnis, Sie zu kontaktieren. <strong>Wir geben Ihre Daten nicht weiter!</strong></div>
        </div>
        <div>
            <input id="agb" type="checkbox" v-model="form.agb" @change="$v.form.agb.$touch()"/>
            <label for="agb">&nbsp;Hiermit akzeptiere ich die <a href="/goto/agb" target="_blank">Allgemeinen Geschäftsbedingungen (AGB)</a></label>
            <div v-if="$v.form.agb.$error" class="alert alert-danger">Bitte akzeptieren Sie die AGB, um fortzufahren.</div>
        </div>
        -->
    </div>
</template>

<script>
// import {required} from 'vuelidate/lib/validators'

import {countryRegion} from '@/helper/wtMappings';
import applicationTracking from '@/plugins/webtrekk';
import {numberFormats} from '@/helper/NumberFormats';

export default {
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        acceptLegal: false,
      },
    };
  },
  mounted: function () {
    let wtContentId = `[${this.$i18n.locale}|${this.wizardData.micrositeLanguage}].[Checkout Step 3]`;
    let wtData = {
      category: {
        1: `${this.wizardData.micrositeLanguage}|${this.$i18n.locale}`,  // Country | Language (CG 1): language|country - Bsp. "en|india"
        7: `[${this.$i18n.locale}|${this.wizardData.micrositeLanguage}].[Checkout Step 3]`,  // English page name (CG7): [country|language].[page name] - Bsp. "[spain|es].[Checkout Step 1]"
        14: 'docebo checkout',  // Page Type (CG 14)
      },
      parameter: {
        1: 'A',  // TUV Business Stream (PP 1)
        2: 'A.02 Training and HR Development',  // TUV Business Field (PP 2)
        3: 'no Service ID',  // Service ID (PP 3)
        4: `${this.$i18n.locale}`,  // TUV Country Website (PP 4)
        5: countryRegion[this.$i18n.locale] || '',  // TUV Region (PP 5)
        7: 'docebo',  // Platform (PP 7)
        14: 'no login status',  // Login Status (PP 14)
      },
    };
    applicationTracking.page(wtContentId, wtData);
    applicationTracking.trackPage();
  },
  activated() {
    // this.form.kontoinhaber = `${this.wizardData.vorname} ${this.wizardData.nachname}`
  },
  validations: {
    form: {
      /* iban: {},
      kontoinhaber: {},
      agb: {
          checked (val) {
              return val
          }
      },
      kontakt: {
          checked (val) {
              return val
          }
      } */
      acceptLegal: {
        checked(val) {
          return val;
        },
      },
    },
  },
  methods: {
    getLegalUrl() {
      return this.$t('legalTermsUrl');
    },
    submit() {
      this.$v.$touch();
      localStorage.removeItem('tra_checkout_cart');
      return new Promise((resolve, reject) => {
        if (!this.$v.$invalid) {
          resolve({
            iban: this.form.iban,
            kontoinhaber: this.form.kontoinhaber,
          });

          let wtProducts = [];
          let orderValue = 0;
          for (let pid in this.wizardData.cart) {
            let product = this.wizardData.cart[pid];
            let productPrice = product.price_vat_incl + product.exam_fee_vat_incl > 0 ? product.price_vat_incl + product.exam_fee_vat_incl : product.price_vat_excl + product.exam_fee_vat_excl;
            let wtProduct = {
              id: `${product.courseID}`,
              currency: numberFormats[this.$t('langCode')].currency.currency,
              cost: productPrice,
              quantity: product.participants.length,
              /* parameter: {
                1: 'L'
              },
              category: {
                1: 'tops',
                2: 'noname'
              } */
            };
            orderValue += productPrice * product.participants.length;
            wtProducts.push(wtProduct);
          }
          let orderData = {
            value: orderValue,
            // id: 'M-12345',
            // couponValue: 10.00,
            paymentMethod: 'bill',
            /* parameter: {
              5: 'bill',
            }, */
          };
          applicationTracking.setOrderConfirmed(wtProducts, orderData);
          applicationTracking.track();

        } else {
          reject('invalid address');
        }
      });
    },
  },
};
</script>

<style scoped>
    address {
        margin: 0.5em;
    }
</style>