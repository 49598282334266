<template>
    <v-app>
        <header class="header header--sub-brand">
            <div class="header__inner">
                <div class="logo">
                    <a href="/"><!-- <img alt="Logo" src="./assets/img/logo.svg"
                                     style="max-width: 100%; max-height:100%;"> -->
                        <svg enable-background="new 0 0 396.851 99.213" height="99.213px" id="Markenzeichen"
                             version="1.1" viewBox="0 0 396.851 99.213" width="396.851px" x="0px"
                             xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink"
                             y="0px">
<g>
<g>
<path d="M105.215,31.779h-8.837v-4.85h24.045v4.85h-8.837v24.826h-6.371V31.779z"></path>
    <path d="M131.397,26.929v18.085c0,0.822,0.048,1.665,0.144,2.527c0.096,0.863,0.349,1.651,0.76,2.364
c0.412,0.713,1.049,1.294,1.912,1.746c0.863,0.452,2.076,0.679,3.638,0.679c0.247,0,0.609-0.014,1.089-0.041
c0.479-0.027,1-0.124,1.562-0.288s1.116-0.425,1.665-0.781c0.548-0.355,1.027-0.849,1.438-1.479
c0.165-0.219,0.301-0.466,0.411-0.739c0.109-0.274,0.206-0.61,0.288-1.008c0.082-0.396,0.144-0.862,0.185-1.397
c0.041-0.534,0.062-1.171,0.062-1.911V26.929h6.207v18.537c0,2.22-0.397,4.077-1.192,5.574c-0.795,1.496-1.823,2.699-3.083,3.611
s-2.658,1.562-4.192,1.948c-1.535,0.387-3.056,0.58-4.562,0.58c-0.74,0-1.61-0.055-2.61-0.165c-1-0.111-2.014-0.353-3.042-0.726
c-1.027-0.373-2.035-0.898-3.021-1.575s-1.823-1.581-2.507-2.713c-0.713-1.15-1.145-2.308-1.294-3.473
c-0.151-1.165-0.226-2.446-0.226-3.844V26.929H131.397z M132.342,18.425h4.192v4.85h-4.192V18.425z M139.412,18.425h4.192v4.85
h-4.192V18.425z"></path>
    <path d="M162.305,26.929l7.769,23.387l7.809-23.387h6.289l-10.934,29.676h-6.946l-10.933-29.676H162.305z"></path>
    <path d="M189.72,26.929h10.604c0.383,0,0.85,0.007,1.398,0.021c0.547,0.014,1.137,0.062,1.766,0.145
c0.631,0.082,1.262,0.22,1.891,0.411c0.631,0.192,1.221,0.467,1.768,0.823c1.125,0.714,1.953,1.599,2.488,2.655
c0.533,1.056,0.801,2.188,0.801,3.395c0,1.043-0.186,2.017-0.555,2.922c-0.371,0.905-0.885,1.688-1.541,2.346
c-0.631,0.632-1.254,1.077-1.871,1.338c-0.615,0.261-1.158,0.46-1.623,0.597c0.713,0.191,1.328,0.425,1.85,0.698
c0.52,0.273,0.959,0.623,1.314,1.047s0.645,0.958,0.863,1.601c0.219,0.644,0.385,1.43,0.494,2.36
c0.109,0.876,0.211,1.697,0.309,2.463c0.096,0.767,0.211,1.52,0.35,2.258c0.137,0.739,0.293,1.484,0.473,2.237
c0.178,0.753,0.416,1.54,0.719,2.36h-4.398c-0.164-0.493-0.314-1.075-0.451-1.747c-0.139-0.671-0.262-1.362-0.371-2.075
s-0.205-1.418-0.287-2.117c-0.082-0.698-0.164-1.336-0.246-1.911c-0.139-1.068-0.316-1.959-0.535-2.672
c-0.219-0.712-0.561-1.28-1.023-1.705c-0.465-0.425-1.092-0.727-1.885-0.904c-0.791-0.179-1.803-0.268-3.029-0.268h-5.16v13.399
h-4.11V26.929z M193.748,39.753h6.473c0.109,0,0.32,0,0.635,0s0.67-0.021,1.064-0.062c0.396-0.041,0.793-0.11,1.189-0.206
c0.395-0.097,0.73-0.227,1.002-0.393c0.137-0.082,0.32-0.213,0.555-0.392c0.232-0.179,0.457-0.427,0.676-0.743
s0.402-0.695,0.553-1.136s0.225-0.977,0.225-1.61c0-1.21-0.232-2.139-0.695-2.786c-0.465-0.646-1.012-1.121-1.639-1.425
c-0.629-0.302-1.277-0.48-1.947-0.536c-0.668-0.055-1.207-0.083-1.617-0.083h-6.473V39.753z"></path>
    <path d="M216.601,26.93h3.904v12.083c0.63-1.096,1.542-1.938,2.733-2.527c1.192-0.59,2.514-0.884,3.967-0.884
c0.548,0,1.137,0.055,1.768,0.164c0.63,0.109,1.246,0.288,1.85,0.534c0.602,0.247,1.157,0.583,1.664,1.007
c0.507,0.426,0.911,0.953,1.213,1.583c0.109,0.22,0.205,0.445,0.287,0.678c0.083,0.233,0.15,0.534,0.206,0.904
c0.055,0.37,0.103,0.815,0.144,1.336s0.062,1.151,0.062,1.891v12.906h-3.945V44.398c0-0.466-0.021-1.028-0.062-1.686
s-0.206-1.295-0.493-1.911c-0.288-0.616-0.733-1.144-1.336-1.583c-0.604-0.438-1.453-0.657-2.549-0.657
c-0.713,0-1.343,0.097-1.891,0.288c-0.549,0.191-1.021,0.432-1.418,0.719c-0.397,0.288-0.72,0.59-0.966,0.904
c-0.247,0.315-0.438,0.596-0.575,0.843c-0.329,0.575-0.521,1.288-0.576,2.138s-0.082,1.589-0.082,2.219v10.934h-3.904V26.93z"></path>
    <path d="M243.439,46.987c-0.027,1.063,0.068,2.044,0.288,2.943c0.219,0.899,0.568,1.683,1.048,2.351
c0.479,0.668,1.096,1.186,1.85,1.553c0.753,0.368,1.664,0.552,2.733,0.552c1.26,0,2.343-0.339,3.247-1.017
c0.904-0.679,1.438-1.751,1.603-3.217h3.863c-0.055,0.521-0.178,1.172-0.369,1.952c-0.192,0.781-0.658,1.569-1.397,2.363
c-0.22,0.247-0.521,0.535-0.904,0.863c-0.385,0.329-0.863,0.631-1.438,0.904c-0.576,0.274-1.254,0.507-2.035,0.699
c-0.78,0.191-1.692,0.287-2.733,0.287c-3.261,0-5.672-0.945-7.233-2.836c-1.562-1.891-2.343-4.466-2.343-7.727
c0-2.028,0.273-3.87,0.821-5.528s1.63-3.049,3.247-4.172c0.493-0.356,1.008-0.631,1.542-0.822s1.055-0.343,1.562-0.452
s0.979-0.178,1.418-0.206c0.438-0.026,0.808-0.041,1.11-0.041c1.534,0,2.828,0.206,3.884,0.617
c1.055,0.41,1.911,0.952,2.569,1.623c0.657,0.672,1.164,1.425,1.521,2.261c0.355,0.836,0.609,1.686,0.76,2.548
c0.15,0.863,0.227,1.692,0.227,2.487s0,1.466,0,2.014H243.439z M254.331,44.274c0-0.493-0.041-1.089-0.123-1.788
c-0.082-0.698-0.295-1.362-0.637-1.993c-0.343-0.63-0.857-1.171-1.542-1.623s-1.616-0.679-2.795-0.679
c-0.63,0-1.273,0.103-1.932,0.309c-0.657,0.205-1.253,0.548-1.788,1.027c-0.534,0.479-0.979,1.104-1.336,1.87
c-0.356,0.768-0.548,1.727-0.575,2.877H254.331z"></path>
    <path d="M263.127,26.93h4.645v4.233h-4.645V26.93z M263.538,35.971h3.864v20.634h-3.864V35.971z"></path>
    <path d="M274.267,38.356l-0.041-2.385h3.863l0.041,3.33c0.109-0.22,0.295-0.535,0.556-0.945c0.26-0.411,0.644-0.822,1.15-1.233
s1.157-0.774,1.952-1.089c0.795-0.315,1.781-0.473,2.96-0.473c0.466,0,1.116,0.068,1.952,0.205
c0.836,0.138,1.665,0.445,2.486,0.925c0.822,0.479,1.534,1.186,2.138,2.117c0.603,0.932,0.904,2.192,0.904,3.781v14.016h-3.946
V43.494c0-0.685-0.068-1.329-0.205-1.933c-0.138-0.602-0.438-1.137-0.904-1.603c-0.493-0.466-1.062-0.815-1.706-1.048
s-1.295-0.35-1.952-0.35c-0.713,0-1.391,0.123-2.034,0.37c-0.645,0.246-1.213,0.631-1.706,1.15
c-0.493,0.521-0.891,1.165-1.192,1.932c-0.302,0.768-0.452,1.672-0.452,2.713v11.879h-3.863V38.356z"></path>
    <path d="M298.722,26.93h3.863v29.635h-3.863V26.93z"></path>
    <path d="M322.027,56.605c-0.138-0.521-0.233-1.027-0.288-1.521s-0.082-1.014-0.082-1.562c-0.466,0.85-0.979,1.521-1.541,2.015
c-0.562,0.493-1.131,0.87-1.706,1.13s-1.13,0.425-1.664,0.493c-0.535,0.068-1.021,0.103-1.46,0.103
c-1.178,0-2.219-0.157-3.123-0.473c-0.904-0.314-1.665-0.746-2.281-1.294c-0.617-0.548-1.083-1.192-1.397-1.933
c-0.315-0.739-0.473-1.534-0.473-2.384c0-0.411,0.047-0.876,0.144-1.397c0.096-0.52,0.273-1.048,0.534-1.582
s0.623-1.068,1.09-1.604c0.465-0.534,1.082-1.021,1.85-1.459c0.547-0.301,1.15-0.541,1.808-0.719
c0.658-0.179,1.315-0.315,1.974-0.411c0.657-0.096,1.28-0.157,1.87-0.186c0.589-0.026,1.089-0.041,1.5-0.041
c0.493,0,0.932,0.008,1.315,0.021c0.383,0.015,0.876,0.049,1.479,0.104c0-0.631-0.007-1.186-0.021-1.665s-0.041-0.891-0.082-1.233
c-0.041-0.342-0.11-0.65-0.206-0.925c-0.096-0.273-0.24-0.52-0.432-0.739c-0.384-0.521-0.938-0.877-1.664-1.069
c-0.727-0.191-1.363-0.287-1.911-0.287c-0.986,0-1.754,0.144-2.302,0.432c-0.549,0.287-0.96,0.623-1.233,1.007
c-0.274,0.384-0.445,0.774-0.514,1.171c-0.068,0.397-0.103,0.692-0.103,0.884h-3.864c0.055-0.548,0.157-1.157,0.309-1.829
c0.15-0.671,0.527-1.362,1.13-2.075c0.384-0.438,0.85-0.802,1.398-1.089c0.547-0.288,1.115-0.515,1.705-0.679
c0.589-0.164,1.192-0.28,1.809-0.35c0.616-0.067,1.198-0.103,1.747-0.103c0.739,0,1.486,0.062,2.24,0.185
c0.753,0.124,1.459,0.309,2.116,0.556c0.658,0.246,1.247,0.562,1.768,0.945s0.918,0.836,1.192,1.356
c0.328,0.63,0.527,1.198,0.596,1.705c0.068,0.508,0.103,0.994,0.103,1.459l-0.164,9.865c0,0.082,0.006,0.302,0.021,0.657
c0.014,0.356,0.026,0.781,0.041,1.274c0.014,0.493,0.041,1.027,0.082,1.603c0.041,0.576,0.103,1.124,0.186,1.645H322.027z
M321.575,46.576c-0.356-0.055-0.698-0.088-1.027-0.103c-0.329-0.014-0.727-0.021-1.192-0.021c-0.384,0-0.986,0.021-1.809,0.062
c-0.821,0.041-1.65,0.192-2.486,0.452c-0.836,0.261-1.569,0.679-2.199,1.254c-0.631,0.575-0.945,1.384-0.945,2.425
c0,0.329,0.062,0.699,0.186,1.11c0.123,0.41,0.342,0.801,0.657,1.171c0.314,0.37,0.746,0.679,1.295,0.925
c0.548,0.247,1.26,0.37,2.137,0.37c1.974,0,3.438-0.74,4.398-2.22c0.219-0.355,0.391-0.698,0.514-1.027s0.212-0.699,0.268-1.11
c0.054-0.41,0.096-0.883,0.123-1.418C321.52,47.912,321.548,47.289,321.575,46.576z"></path>
    <path d="M331.81,38.356l-0.041-2.385h3.863l0.041,3.33c0.109-0.22,0.295-0.535,0.556-0.945c0.26-0.411,0.644-0.822,1.15-1.233
s1.157-0.774,1.952-1.089c0.795-0.315,1.781-0.473,2.96-0.473c0.466,0,1.116,0.068,1.952,0.205
c0.836,0.138,1.665,0.445,2.486,0.925c0.822,0.479,1.534,1.186,2.138,2.117c0.603,0.932,0.904,2.192,0.904,3.781v14.016h-3.946
V43.494c0-0.685-0.068-1.329-0.205-1.933c-0.138-0.602-0.438-1.137-0.904-1.603c-0.493-0.466-1.062-0.815-1.706-1.048
s-1.295-0.35-1.952-0.35c-0.713,0-1.391,0.123-2.034,0.37c-0.645,0.246-1.213,0.631-1.706,1.15
c-0.493,0.521-0.891,1.165-1.192,1.932c-0.302,0.768-0.452,1.672-0.452,2.713v11.879h-3.863V38.356z"></path>
    <path d="M370.486,26.93h3.863v26.347c0,0.109,0.007,0.295,0.021,0.555c0.014,0.261,0.027,0.549,0.041,0.863
c0.014,0.315,0.027,0.637,0.041,0.966s0.034,0.631,0.062,0.904h-3.863l-0.082-3.288c-0.164,0.302-0.377,0.645-0.637,1.027
c-0.261,0.384-0.665,0.768-1.213,1.151c-0.85,0.575-1.733,0.973-2.651,1.191s-1.733,0.329-2.445,0.329
c-0.686,0-1.535-0.123-2.549-0.37c-1.014-0.246-1.986-0.74-2.918-1.479c-0.932-0.74-1.733-1.795-2.404-3.165
c-0.672-1.37-1.007-3.191-1.007-5.467c0-1.973,0.26-3.664,0.78-5.076c0.521-1.411,1.213-2.575,2.076-3.493
s1.855-1.597,2.979-2.035c1.124-0.438,2.288-0.657,3.494-0.657c1.096,0,2.027,0.144,2.795,0.432
c0.767,0.287,1.397,0.623,1.891,1.007s0.869,0.768,1.13,1.15c0.261,0.385,0.459,0.686,0.597,0.904V26.93z M370.609,45.979
c0-1.51-0.151-2.712-0.452-3.605c-0.302-0.893-0.672-1.613-1.109-2.163c-0.411-0.549-0.994-1.037-1.747-1.463
c-0.754-0.426-1.679-0.639-2.774-0.639c-0.96,0-1.761,0.172-2.404,0.515c-0.645,0.344-1.172,0.783-1.583,1.319
c-0.411,0.535-0.733,1.126-0.966,1.771c-0.233,0.646-0.404,1.278-0.514,1.896c-0.109,0.618-0.172,1.182-0.185,1.689
c-0.015,0.509-0.021,0.873-0.021,1.093c0,0.906,0.075,1.833,0.226,2.781c0.15,0.947,0.432,1.8,0.843,2.555
s0.986,1.374,1.727,1.854c0.739,0.481,1.698,0.722,2.877,0.722c1.507,0,2.678-0.357,3.514-1.071s1.439-1.545,1.809-2.493
c0.37-0.948,0.589-1.875,0.658-2.781C370.574,47.051,370.609,46.392,370.609,45.979z"></path>
    <path d="M388.814,24.095c1.105,0,2.146,0.21,3.125,0.628c0.978,0.418,1.828,0.988,2.551,1.711s1.297,1.573,1.722,2.551
c0.426,0.979,0.639,2.027,0.639,3.146c0,1.135-0.213,2.19-0.639,3.168c-0.425,0.979-1.002,1.832-1.732,2.562
c-0.729,0.73-1.584,1.305-2.562,1.723s-2.013,0.627-3.104,0.627c-1.092,0-2.123-0.209-3.094-0.627s-1.821-0.992-2.551-1.723
c-0.73-0.729-1.312-1.583-1.744-2.562c-0.432-0.978-0.648-2.033-0.648-3.168c0-1.119,0.217-2.168,0.648-3.146
c0.433-0.978,1.018-1.828,1.754-2.551c0.737-0.723,1.591-1.293,2.562-1.711C386.713,24.305,387.736,24.095,388.814,24.095z
M388.814,25.052c-0.965,0-1.875,0.188-2.732,0.563c-0.857,0.376-1.608,0.882-2.254,1.52c-0.645,0.639-1.155,1.386-1.53,2.243
c-0.376,0.858-0.563,1.775-0.563,2.753c0,1.007,0.188,1.942,0.563,2.807c0.375,0.865,0.886,1.619,1.53,2.265
c0.646,0.645,1.396,1.151,2.254,1.52c0.857,0.369,1.768,0.553,2.732,0.553c0.978,0,1.894-0.188,2.753-0.563
c0.857-0.375,1.608-0.886,2.253-1.53c0.646-0.645,1.151-1.399,1.521-2.265c0.367-0.864,0.553-1.792,0.553-2.785
c0-0.949-0.181-1.853-0.542-2.71s-0.861-1.608-1.499-2.254c-0.638-0.645-1.39-1.158-2.254-1.541S389.806,25.052,388.814,25.052z
M385.88,27.752h3.062c0.156,0,0.396,0.011,0.723,0.031c0.326,0.021,0.652,0.1,0.979,0.234c0.325,0.135,0.612,0.351,0.86,0.648
s0.372,0.716,0.372,1.254c0,0.071-0.007,0.195-0.021,0.372c-0.015,0.178-0.075,0.372-0.181,0.585
c-0.106,0.213-0.27,0.425-0.489,0.638s-0.528,0.376-0.925,0.489c0.453,0.113,0.769,0.283,0.946,0.51
c0.177,0.228,0.301,0.61,0.372,1.148c0.07,0.539,0.134,1.021,0.191,1.445c0.056,0.426,0.162,0.851,0.318,1.275h-1.233
c-0.042-0.141-0.081-0.272-0.116-0.393c-0.036-0.12-0.067-0.263-0.096-0.426c-0.029-0.162-0.061-0.357-0.096-0.584
c-0.036-0.227-0.074-0.511-0.117-0.851c-0.028-0.326-0.078-0.596-0.148-0.808c-0.071-0.213-0.174-0.383-0.309-0.511
s-0.315-0.216-0.542-0.266s-0.518-0.074-0.872-0.074h-1.488v3.911h-1.19V27.752z M387.07,31.451h1.871
c0.142,0,0.312-0.007,0.511-0.021c0.198-0.014,0.386-0.063,0.563-0.148c0.177-0.085,0.325-0.216,0.446-0.394
c0.12-0.177,0.181-0.429,0.181-0.755c0-0.34-0.061-0.603-0.181-0.786c-0.121-0.185-0.266-0.319-0.437-0.404
c-0.17-0.085-0.354-0.135-0.553-0.148c-0.198-0.015-0.375-0.021-0.531-0.021h-1.871V31.451z"></path>
</g>
    <g>
<path d="M110.778,78.151c-0.152-0.963-0.61-1.738-1.375-2.324c-0.765-0.585-1.847-0.879-3.246-0.879
c-0.964,0-1.838,0.138-2.622,0.411c-0.784,0.274-1.45,0.714-1.999,1.318c-0.453,0.492-0.822,1.021-1.105,1.588
c-0.283,0.566-0.506,1.139-0.666,1.715c-0.161,0.576-0.269,1.147-0.327,1.715c-0.056,0.566-0.084,1.087-0.084,1.559
c0,0.436,0.028,0.932,0.084,1.488c0.058,0.558,0.157,1.13,0.298,1.715c0.142,0.586,0.331,1.158,0.567,1.715
c0.236,0.558,0.543,1.063,0.921,1.517c0.34,0.379,0.718,0.7,1.134,0.964c0.416,0.266,0.855,0.473,1.318,0.624
s0.949,0.26,1.46,0.326c0.51,0.066,1.021,0.099,1.531,0.099c1.171,0,2.125-0.085,2.863-0.255s1.266-0.302,1.588-0.396v-6.066
h-4.677v-2.381h7.512v10.116c-0.303,0.095-0.685,0.214-1.148,0.355c-0.463,0.142-1.006,0.279-1.63,0.412
c-0.624,0.132-1.314,0.246-2.07,0.341s-1.568,0.142-2.438,0.142c-1.89,0-3.42-0.24-4.592-0.723
c-1.172-0.481-2.117-1.091-2.834-1.828c-1.021-1.021-1.753-2.211-2.197-3.571c-0.444-1.361-0.666-2.769-0.666-4.224
c0-0.511,0.047-1.167,0.142-1.971c0.094-0.803,0.293-1.653,0.595-2.551s0.747-1.781,1.332-2.65
c0.586-0.869,1.361-1.635,2.325-2.296c0.51-0.341,1.044-0.604,1.602-0.794c0.557-0.188,1.096-0.335,1.615-0.439
c0.52-0.104,0.997-0.165,1.432-0.185c0.435-0.019,0.784-0.028,1.049-0.028c0.397,0,0.845,0.02,1.346,0.057
c0.5,0.038,1.016,0.133,1.545,0.284c0.528,0.151,1.063,0.373,1.602,0.666s1.053,0.685,1.545,1.176
c0.302,0.322,0.543,0.634,0.723,0.936c0.179,0.303,0.312,0.596,0.396,0.879c0.085,0.283,0.143,0.549,0.171,0.794
c0.028,0.246,0.052,0.463,0.071,0.651H110.778z"></path>
        <path d="M120.388,86.883c-0.019,0.732,0.047,1.409,0.198,2.029c0.151,0.62,0.392,1.161,0.723,1.621
c0.33,0.461,0.755,0.818,1.275,1.071c0.52,0.254,1.148,0.381,1.885,0.381c0.869,0,1.616-0.234,2.24-0.702s0.992-1.207,1.105-2.218
h2.665c-0.038,0.359-0.124,0.808-0.255,1.347c-0.132,0.538-0.454,1.082-0.964,1.63c-0.151,0.17-0.359,0.368-0.624,0.595
s-0.595,0.436-0.992,0.624c-0.396,0.188-0.864,0.35-1.403,0.481s-1.167,0.198-1.886,0.198c-2.249,0-3.911-0.651-4.988-1.955
c-1.077-1.305-1.616-3.08-1.616-5.329c0-1.398,0.189-2.67,0.567-3.813c0.378-1.143,1.124-2.102,2.239-2.877
c0.34-0.245,0.694-0.435,1.063-0.566s0.728-0.236,1.077-0.312c0.35-0.075,0.676-0.122,0.979-0.142
c0.302-0.019,0.557-0.028,0.765-0.028c1.058,0,1.951,0.142,2.679,0.426c0.727,0.283,1.318,0.656,1.772,1.119
c0.454,0.464,0.803,0.983,1.049,1.56c0.245,0.576,0.42,1.162,0.524,1.757c0.104,0.596,0.156,1.167,0.156,1.715
c0,0.549,0,1.012,0,1.39H120.388z M127.899,85.012c0-0.34-0.028-0.751-0.085-1.233c-0.057-0.481-0.204-0.939-0.439-1.374
c-0.236-0.435-0.591-0.809-1.063-1.12c-0.473-0.312-1.115-0.468-1.928-0.468c-0.435,0-0.878,0.071-1.332,0.213
s-0.865,0.378-1.233,0.708c-0.368,0.331-0.676,0.762-0.921,1.29c-0.247,0.529-0.378,1.19-0.397,1.984H127.899z"></path>
        <path d="M134.419,80.93l-0.028-1.644h2.665l0.028,2.296c0.075-0.151,0.203-0.369,0.383-0.652c0.179-0.283,0.444-0.566,0.793-0.851
c0.35-0.283,0.798-0.533,1.347-0.751c0.548-0.217,1.228-0.326,2.041-0.326c0.32,0,0.77,0.048,1.346,0.142
c0.576,0.095,1.148,0.308,1.715,0.639c0.567,0.33,1.058,0.817,1.474,1.459c0.416,0.643,0.624,1.513,0.624,2.608v9.666h-2.721
v-9.043c0-0.472-0.047-0.916-0.142-1.332c-0.095-0.415-0.303-0.784-0.624-1.105c-0.34-0.321-0.733-0.562-1.177-0.723
c-0.443-0.16-0.892-0.241-1.346-0.241c-0.492,0-0.959,0.085-1.403,0.255c-0.444,0.171-0.836,0.436-1.176,0.794
c-0.34,0.359-0.614,0.804-0.822,1.332c-0.208,0.529-0.312,1.153-0.312,1.871v8.192h-2.665V80.93z"></path>
        <path d="M160.129,93.516c-0.095-0.358-0.161-0.709-0.198-1.049c-0.038-0.34-0.057-0.699-0.057-1.077
c-0.322,0.586-0.677,1.049-1.063,1.389c-0.388,0.341-0.78,0.601-1.177,0.78c-0.396,0.179-0.779,0.292-1.148,0.34
c-0.368,0.047-0.704,0.07-1.006,0.07c-0.812,0-1.531-0.108-2.154-0.325c-0.624-0.218-1.148-0.515-1.573-0.894
c-0.425-0.378-0.747-0.821-0.964-1.332c-0.217-0.51-0.326-1.058-0.326-1.645c0-0.283,0.033-0.604,0.099-0.963
c0.066-0.359,0.188-0.724,0.369-1.092c0.179-0.368,0.43-0.737,0.751-1.105s0.747-0.704,1.276-1.006
c0.377-0.208,0.793-0.373,1.247-0.496s0.907-0.218,1.36-0.284c0.454-0.065,0.883-0.108,1.29-0.127
c0.406-0.019,0.75-0.028,1.035-0.028c0.34,0,0.642,0.005,0.907,0.014c0.264,0.01,0.604,0.033,1.021,0.071
c0-0.435-0.005-0.817-0.015-1.148c-0.01-0.33-0.028-0.613-0.057-0.851c-0.028-0.235-0.076-0.448-0.142-0.638
c-0.066-0.188-0.166-0.358-0.297-0.51c-0.265-0.359-0.648-0.604-1.148-0.737c-0.501-0.132-0.94-0.198-1.318-0.198
c-0.68,0-1.209,0.099-1.587,0.298c-0.378,0.198-0.662,0.43-0.851,0.694s-0.307,0.534-0.354,0.808
c-0.048,0.274-0.071,0.478-0.071,0.609h-2.665c0.038-0.378,0.109-0.798,0.213-1.262c0.104-0.462,0.364-0.939,0.779-1.431
c0.265-0.303,0.585-0.553,0.964-0.752c0.378-0.198,0.77-0.354,1.176-0.468c0.406-0.113,0.822-0.193,1.248-0.24
s0.826-0.071,1.205-0.071c0.51,0,1.024,0.043,1.545,0.128c0.52,0.085,1.006,0.212,1.46,0.383c0.453,0.17,0.859,0.387,1.219,0.651
c0.359,0.265,0.633,0.577,0.822,0.936c0.227,0.436,0.363,0.827,0.411,1.177c0.047,0.35,0.071,0.685,0.071,1.006l-0.113,6.804
c0,0.057,0.004,0.208,0.014,0.453c0.009,0.246,0.019,0.539,0.028,0.879c0.009,0.34,0.028,0.709,0.057,1.105
c0.028,0.396,0.071,0.775,0.128,1.134H160.129z M159.817,86.599c-0.246-0.037-0.482-0.061-0.709-0.07
c-0.227-0.01-0.501-0.015-0.822-0.015c-0.265,0-0.681,0.015-1.248,0.043c-0.567,0.028-1.139,0.133-1.715,0.312
c-0.577,0.18-1.082,0.468-1.517,0.865c-0.435,0.396-0.652,0.954-0.652,1.672c0,0.227,0.043,0.482,0.128,0.766
s0.236,0.553,0.454,0.808c0.217,0.255,0.514,0.468,0.893,0.638c0.377,0.17,0.869,0.255,1.474,0.255c1.36,0,2.372-0.51,3.033-1.53
c0.151-0.245,0.27-0.481,0.354-0.709c0.085-0.227,0.146-0.481,0.184-0.765c0.038-0.284,0.066-0.609,0.084-0.979
C159.779,87.521,159.798,87.091,159.817,86.599z"></path>
        <path d="M169.455,79.286v9.155c0,0.341,0.028,0.699,0.085,1.077c0.057,0.379,0.194,0.719,0.411,1.021
c0.217,0.303,0.524,0.553,0.921,0.751c0.397,0.199,0.936,0.298,1.616,0.298c0.661,0,1.29-0.122,1.885-0.368
c0.595-0.245,1.082-0.652,1.459-1.219c0.302-0.435,0.477-0.897,0.524-1.39c0.047-0.491,0.071-0.916,0.071-1.275v-8.05h2.75v11.564
c0,0.076,0.004,0.218,0.014,0.426c0.009,0.208,0.019,0.444,0.028,0.709c0.009,0.265,0.018,0.538,0.028,0.821
c0.009,0.284,0.014,0.521,0.014,0.709h-2.778l-0.057-2.438c-0.132,0.245-0.297,0.52-0.496,0.821
c-0.199,0.303-0.468,0.582-0.808,0.837c-0.34,0.255-0.775,0.473-1.304,0.651c-0.529,0.18-1.172,0.27-1.928,0.27
c-0.889,0-1.654-0.118-2.296-0.354c-0.643-0.235-1.177-0.566-1.602-0.992c-0.425-0.425-0.742-0.936-0.95-1.53
c-0.208-0.596-0.312-1.242-0.312-1.941v-9.553H169.455z"></path>
        <path d="M183.939,90.114h3.146v3.401h-3.146V90.114z"></path>
        <path d="M199.561,73.05h7.313c0.266,0,0.587,0.005,0.965,0.014c0.378,0.01,0.784,0.043,1.218,0.1
c0.436,0.057,0.87,0.151,1.306,0.284c0.434,0.133,0.84,0.321,1.219,0.567c0.774,0.492,1.346,1.103,1.715,1.831
c0.368,0.729,0.553,1.509,0.553,2.341c0,0.72-0.129,1.391-0.383,2.016c-0.255,0.624-0.609,1.164-1.063,1.618
c-0.435,0.436-0.864,0.742-1.289,0.922c-0.427,0.181-0.799,0.317-1.12,0.412c0.491,0.132,0.917,0.293,1.275,0.481
s0.661,0.43,0.907,0.722c0.245,0.292,0.443,0.66,0.596,1.104c0.15,0.444,0.265,0.986,0.339,1.629
c0.075,0.604,0.146,1.17,0.213,1.698s0.146,1.048,0.241,1.557c0.095,0.51,0.203,1.024,0.325,1.543
c0.124,0.52,0.288,1.062,0.496,1.628h-3.033c-0.112-0.34-0.217-0.741-0.311-1.205c-0.095-0.463-0.18-0.939-0.256-1.431
c-0.075-0.491-0.141-0.979-0.197-1.46c-0.058-0.482-0.113-0.922-0.17-1.318c-0.096-0.737-0.218-1.351-0.369-1.843
c-0.151-0.491-0.387-0.883-0.706-1.176c-0.32-0.293-0.753-0.501-1.3-0.624c-0.546-0.123-1.242-0.185-2.089-0.185h-3.559v9.241
h-2.835V73.05z M202.34,81.894h4.463c0.075,0,0.223,0,0.438,0c0.218,0,0.462-0.014,0.736-0.043
c0.271-0.028,0.546-0.076,0.819-0.143c0.272-0.066,0.502-0.156,0.691-0.271c0.094-0.057,0.222-0.146,0.382-0.27
c0.159-0.123,0.315-0.294,0.466-0.513s0.278-0.479,0.382-0.783c0.103-0.304,0.155-0.674,0.155-1.11
c0-0.835-0.161-1.476-0.48-1.922c-0.32-0.446-0.697-0.773-1.131-0.982c-0.434-0.208-0.88-0.332-1.342-0.37
c-0.461-0.038-0.833-0.057-1.116-0.057h-4.463V81.894z"></path>
        <path d="M218.186,73.106h3.203v2.919h-3.203V73.106z M218.469,79.286h2.665v14.229h-2.665V79.286z"></path>
        <path d="M234.144,83.85c-0.02-0.17-0.057-0.421-0.113-0.751c-0.057-0.331-0.18-0.661-0.369-0.992
c-0.188-0.331-0.467-0.619-0.836-0.865c-0.369-0.245-0.874-0.368-1.518-0.368c-0.926,0-1.639,0.227-2.139,0.681
c-0.501,0.453-0.865,0.982-1.092,1.587c-0.227,0.605-0.359,1.205-0.396,1.8c-0.038,0.596-0.058,1.025-0.058,1.29
c0,0.737,0.053,1.441,0.157,2.112c0.104,0.671,0.287,1.271,0.553,1.8c0.264,0.529,0.623,0.949,1.077,1.262
c0.453,0.312,1.038,0.467,1.757,0.467c0.567,0,1.044-0.094,1.432-0.283c0.387-0.188,0.699-0.435,0.936-0.737
c0.236-0.302,0.41-0.638,0.523-1.006c0.114-0.368,0.181-0.723,0.199-1.062h2.693c-0.039,0.699-0.194,1.355-0.469,1.97
c-0.273,0.614-0.656,1.152-1.147,1.615c-0.491,0.464-1.087,0.832-1.786,1.105c-0.699,0.274-1.483,0.411-2.354,0.411
c-0.244,0-0.557-0.019-0.935-0.057s-0.779-0.118-1.204-0.24c-0.426-0.123-0.861-0.308-1.308-0.553
c-0.445-0.246-0.859-0.577-1.241-0.992c-0.209-0.246-0.421-0.529-0.63-0.851c-0.21-0.321-0.396-0.699-0.559-1.134
s-0.291-0.936-0.387-1.503c-0.096-0.566-0.143-1.219-0.143-1.956c0-1.474,0.205-2.707,0.616-3.699
c0.409-0.991,0.935-1.78,1.572-2.366s1.336-1.002,2.092-1.247c0.756-0.246,1.492-0.369,2.211-0.369
c0.227,0,0.523,0.02,0.893,0.057c0.369,0.038,0.756,0.118,1.163,0.241c0.405,0.123,0.812,0.298,1.218,0.524
c0.407,0.227,0.77,0.538,1.092,0.936c0.529,0.604,0.85,1.186,0.964,1.743c0.113,0.558,0.188,1.034,0.228,1.432H234.144z"></path>
        <path d="M239.981,73.078h2.694v8.306c0.435-0.756,1.062-1.337,1.885-1.744c0.821-0.406,1.733-0.609,2.735-0.609
c0.377,0,0.784,0.038,1.219,0.113c0.434,0.076,0.859,0.199,1.275,0.369s0.799,0.401,1.148,0.694s0.627,0.657,0.836,1.091
c0.076,0.152,0.143,0.308,0.199,0.468c0.057,0.161,0.104,0.369,0.142,0.624c0.037,0.255,0.07,0.562,0.099,0.921
c0.027,0.359,0.043,0.794,0.043,1.305v8.9h-2.722v-8.419c0-0.321-0.014-0.709-0.042-1.162s-0.142-0.893-0.341-1.318
c-0.198-0.425-0.505-0.789-0.921-1.091c-0.417-0.303-1.002-0.454-1.758-0.454c-0.492,0-0.926,0.066-1.305,0.198
c-0.377,0.133-0.703,0.298-0.977,0.496c-0.274,0.199-0.496,0.407-0.666,0.624c-0.17,0.218-0.303,0.411-0.398,0.581
c-0.226,0.396-0.357,0.889-0.396,1.474c-0.037,0.586-0.056,1.097-0.056,1.531v7.54h-2.694V73.078z"></path>
        <path d="M254.921,79.229h2.523v-2.977l2.607-0.851v3.827h3.175v1.955h-3.175v8.532c0,0.171,0.009,0.369,0.028,0.596
c0.019,0.227,0.089,0.435,0.213,0.624c0.122,0.188,0.312,0.35,0.566,0.481c0.256,0.133,0.618,0.198,1.092,0.198
c0.265,0,0.515-0.014,0.751-0.042s0.411-0.062,0.524-0.1v2.069c-0.246,0.038-0.554,0.075-0.921,0.113
c-0.37,0.038-0.799,0.057-1.291,0.057c-1.222,0-2.122-0.213-2.701-0.638c-0.58-0.425-0.869-1.224-0.869-2.396v-9.496h-2.523
V79.229z"></path>
        <path d="M266.202,73.106h3.204v2.919h-3.204V73.106z M266.485,79.286h2.666v14.229h-2.666V79.286z"></path>
        <path d="M276.577,94.621c0.02,0.396,0.086,0.766,0.199,1.105s0.297,0.633,0.553,0.879c0.255,0.245,0.59,0.439,1.006,0.581
s0.936,0.213,1.559,0.213c0.926,0,1.645-0.161,2.154-0.482s0.884-0.77,1.12-1.347c0.235-0.576,0.378-1.266,0.426-2.069
c0.046-0.803,0.07-1.687,0.07-2.65c-0.283,0.587-0.647,1.04-1.091,1.361c-0.445,0.321-0.897,0.562-1.361,0.723
c-0.464,0.161-0.897,0.255-1.305,0.283c-0.405,0.028-0.713,0.043-0.92,0.043c-0.322,0-0.677-0.023-1.062-0.071
c-0.389-0.047-0.775-0.132-1.164-0.255c-0.387-0.123-0.764-0.298-1.134-0.524c-0.367-0.227-0.704-0.529-1.005-0.907
c-0.605-0.718-1.025-1.53-1.262-2.438c-0.237-0.907-0.354-1.843-0.354-2.807c0-1.209,0.166-2.263,0.496-3.16
c0.331-0.897,0.784-1.645,1.361-2.239c0.576-0.596,1.247-1.039,2.013-1.333c0.764-0.293,1.582-0.439,2.451-0.439
c0.719,0,1.328,0.076,1.829,0.228c0.5,0.151,0.925,0.34,1.275,0.566c0.35,0.227,0.628,0.464,0.836,0.709
c0.208,0.246,0.386,0.463,0.539,0.651l0.028-1.955h2.607l-0.057,2.579l-0.029,8.9c0,0.851-0.009,1.588-0.027,2.211
c-0.02,0.624-0.053,1.163-0.1,1.616c-0.048,0.453-0.119,0.846-0.213,1.177c-0.095,0.33-0.209,0.628-0.341,0.893
c-0.304,0.567-0.691,1.021-1.165,1.36c-0.475,0.341-0.98,0.596-1.52,0.766c-0.54,0.17-1.095,0.283-1.664,0.34
c-0.566,0.057-1.097,0.085-1.59,0.085c-0.947,0-1.752-0.07-2.414-0.212c-0.663-0.142-1.213-0.326-1.648-0.553
c-0.436-0.228-0.776-0.496-1.022-0.809c-0.246-0.312-0.436-0.638-0.568-0.978s-0.218-0.686-0.257-1.035
c-0.037-0.35-0.066-0.685-0.084-1.006H276.577z M279.668,91.305c0.793,0,1.431-0.128,1.913-0.384
c0.48-0.256,0.883-0.602,1.205-1.038c0.132-0.189,0.258-0.388,0.382-0.596c0.122-0.209,0.228-0.465,0.312-0.768
c0.084-0.304,0.15-0.673,0.198-1.109c0.047-0.436,0.07-0.976,0.07-1.62c0-0.909-0.096-1.634-0.283-2.175
c-0.188-0.539-0.464-0.999-0.822-1.378s-0.808-0.682-1.346-0.91c-0.539-0.227-1.111-0.341-1.715-0.341
c-0.852,0-1.526,0.189-2.027,0.569c-0.502,0.379-0.884,0.838-1.148,1.378s-0.438,1.108-0.523,1.706
c-0.086,0.597-0.128,1.122-0.128,1.577c0,0.55,0.051,1.123,0.155,1.72c0.104,0.598,0.298,1.146,0.582,1.648
c0.283,0.503,0.68,0.914,1.189,1.236C278.193,91.144,278.854,91.305,279.668,91.305z"></path>
        <path d="M290.948,90.114h3.146v3.401h-3.146V90.114z"></path>
</g>
    <g>
<path d="M73.993,59.416c-4.689-0.812-9.544-1.253-14.458-1.253c-6.869,0-13.493,0.84-19.85,2.478
c-6.297,1.649-12.99,2.528-19.827,2.528c-2.441,0-4.804-0.103-7.145-0.342l26.955-46.71l16.304,28.176
c-5.62,0.247-11.009,1.052-16.288,2.405c-3.817,0.98-7.744,1.679-11.774,2.076l-4.91,8.503c5.725-0.224,11.337-1.086,16.684-2.424
c6.354-1.644,12.975-2.493,19.843-2.493c3.635,0,7.283,0.243,10.802,0.71l-5.166-8.944L51.811,20.997L39.686,0L0,68.732
c6.332,1.73,12.978,2.566,19.853,2.566c6.84,0,13.536-0.836,19.833-2.566c6.354-1.57,12.975-2.474,19.843-2.474
c6.838,0,13.489,0.903,19.841,2.474L73.993,59.416z" fill="#0071B9"></path>
</g>
</g>
</svg>
                    </a>
                </div>
                <div class="tuv-nav tuv-nav--sub-brand" data-module="nav">
                    <div class="tuv-nav__wrapper">
                        <div class="tuv-nav__mobile-head">
                            <div class="tuv-nav__mobile-head-back">
                                <a href="" class="tuv-nav__toggle-mobile-back">
                                    <i class="fa fa-chevron-left" aria-hidden="true"></i> back
                                </a>
                            </div>
                            <div class="tuv-nav__mobile-head-title">

                            </div>
                        </div>
                        <ul class="tuv-nav__navigation tuv-nav__navigation--first navigation">
                            <li class="">
                                <a :href="getReferer()" @click.prevent="goToOrigin()">{{ $t('backToOrigin') }}&nbsp;
                                    <span class="open-close-icon fa fa-chevron-right"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <div class="tuv-containers tuv-containers--landing">
            <div class="tuv-container tuv-container--headline">
                <h1 v-html="title" /><!-- {{ title }}</h1> -->
                <h2>{{ $t("courseCheckout") }}</h2>
            </div>
            <div class="component">
                <div class="tuv-container bc-lt-100">
                    <FormWizard  @titlechange="setTitle" />
                </div>
            </div>
        </div>
        <footer class="tuv-container-grey footer--landing break-out" data-module="footer">
            <div class="footer-inner-wrap">
                <!-- <div class="footer-top">
                    <div class="footer-inner clearfix">
                        <div class="footer-container">
                            <div class="contact-box">
                                <h4>Contact</h4>
                                <ul class=" universal-list">
                                    <li class="">
                                        <a class="tuv-link" href="#">Link</a>
                                    </li>
                                    <li class="">
                                        <a class="tuv-link" href="#">Link</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-list-box">
                                <ul>
                                    <li class="">
                                        <h4 class="">Sitemap</h4>
                                        <ul class=" universal-list">
                                            <li class="">
                                                <a class="tuv-link" href="#">
                                                        <span class="list-text">
                                                            Intelligent Energy
                                                        </span>
                                                </a>
                                            </li>
                                            <li class="">
                                                <a class="tuv-link" href="#">
                                                        <span class="list-text">
                                                            Future Mobility
                                                        </span>
                                                </a>
                                            </li>
                                            <li class="">
                                                <a class="tuv-link" href="#">
                                                        <span class="list-text">
                                                            Smart Devices
                                                        </span>
                                                </a>
                                            </li>
                                            <li class="">
                                                <a class="tuv-link" href="#">
                                                        <span class="list-text">
                                                            Digital Factory
                                                        </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="social-box">
                                <h4>Connect with us</h4>
                                <ul>
                                    <li class="component tuv-social-share__list__item">
                                        <a href="#" class="tuv-social-share__link">Share</a>
                                    </li>
                                    <li class="component tuv-social-share__list__item">
                                        <a href="#" class="tuv-social-share__link">Share</a>
                                    </li>
                                    <li class="component tuv-social-share__list__item">
                                        <a href="#" class="tuv-social-share__link">Share</a>
                                    </li>
                                    <li class="component tuv-social-share__list__item">
                                        <a href="#" class="tuv-social-share__link">Share</a>
                                    </li>
                                    <li class="component tuv-social-share__list__item">
                                        <a href="#" class="tuv-social-share__link">Share</a>
                                    </li>
                                    <li class="component tuv-social-share__list__item">
                                        <a href="#" class="tuv-social-share__link">Share</a>
                                    </li>
                                    <li class="component tuv-social-share__list__item">
                                        <a href="#" class="tuv-social-share__link">Share</a>
                                    </li>
                                    <li class="component tuv-social-share__list__item">
                                        <a href="#" class="tuv-social-share__link">Share</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="back-top-box h-pull-right">
                            <span class="text-box h-hidden-mobile">Back to top</span>
                            <a href="#" class="back-top-btn">
                                <span class="icon-item fa fa-chevron-up"></span>
                            </a>
                        </div>
                    </div>
                </div> -->
                <div class="footer-bottom">
                    <div class="footer-inner">
                        <div class="footer-box">
                            <div class="col copyright">
                              <span>&copy;</span> TÜV Rheinland 2020
                            </div>
                            <div class="col meta-list-box">
                                <ul class="meta-list clearfix universal-list">
                                    <!-- <li class="">
                                        <a class="tuv-link" href="#">
                                    <span class="list-text">
                                        Imprint
                                    </span>
                                        </a>
                                    </li>
                                    <li class="">
                                        <a class="tuv-link" href="#">
                                    <span class="list-text">
                                        Terms and Conditions
                                    </span>
                                        </a>
                                    </li>
                                    <li class="">
                                        <a class="tuv-link" href="#">
                                    <span class="list-text">
                                        Privacy Policy
                                    </span>
                                        </a>
                                    </li>
                                    <li class="">
                                        <a class="tuv-link" href="#">
                                    <span class="list-text">
                                        Cookie Policy
                                    </span>
                                        </a>
                                    </li> -->
                                  <li>
                                    <a v-if="$te('legalNotation')" :href="getLegalNotationUrl()" class="tuv-link">
                                    <span class="list-text">
                                        {{ $t('legalNotation') }}
                                    </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a class="tuv-link" :href="getLegalUrl()">
                                    <span class="list-text">
                                        {{ $t('legal') }}
                                    </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a class="tuv-link" :href="getDataProtectionUrl()">
                                    <span class="list-text">
                                        {{ $t('dataProtection') }}
                                    </span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div class="col lang-switcher-box clearfix">
                                <div class="tuv-nav__country-switch lang-switcher h-pull-right">
                <span class="tuv-nav__country-switch__select">
                        <!-- CORE-202: add data-show-flags attribute -->
                        <select name="lang" id="lang" class="select2-hidden-accessible" data-show-flags="false">
                         <!--CORE-136-->
                                <option value="#EN" data-flag-id="gb">EN</option>
                                <option value="#DE" data-flag-id="de">DE</option>
                        </select>
                      </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </v-app>
</template>

<script>
import applicationTracking from './plugins/webtrekk';
import FormWizard from './components/FormWizard.vue';

export default {
  name: 'app',
  components: {
    FormWizard,
  },
  data() {
    return {
      title: 'Checkout',
    };
  },
  beforeMount: function () {
    applicationTracking.init();
  },
  methods: {
    setTitle(title) {
      this.title = title
    },
    getLegalUrl() {
      return this.$t('legalTermsUrl');
    },
    getDataProtectionUrl() {
      return this.$t('dataProtectionUrl');
    },
    getLegalNotationUrl() {
      return this.$t('legalNotationUrl');
    },
    getReferer() {
      /*if (document.referrer !== undefined && document.referrer !== '') {
        return document.referrer;
      } else {
        return 'https://academy.tuv.com';
      }*/
      // return 'https://www.tuv.com/landingpage/en/tuv-rheinland-academy/main-navigation/business-solutions/digital-learning.html';
      return this.$t('backToOriginUrl');
    },
    goToOrigin() {
        this.$root.$emit('saveCart');
        this.$nextTick(() => {
          window.location.href = this.getReferer();
        });
      },
    }
  };
</script>

<style>
    .theme--light.application {
        background: #fff;
    }
</style>

